import React, { useEffect, useReducer, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Spinner,
  Row,
  Input,
  Progress,
  Accordion,
  AccordionItem,
  UncontrolledDropdown,
  Button,
  Collapse,
  FormGroup,
  UncontrolledTooltip,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownItemProps,
  DropdownMenu,
  Label,
  ModalHeader,
  Modal,
  ModalBody,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
// import * as dayjsLocale from 'dayjs/locale/pt-br';
// import * as antdLocale from 'antd/locale/pt_BR';
import "roster-schedule/dist/css/style.css";
import Loader from "../../Components/Common/Loader";
import moment from "moment";
import axios from "axios";
import DemoData from "./DemoData";
import info from "../../assets/images/info.png";
import img13 from "../../assets/images/small/userimage.png";

import classnames from "classnames";
import ShiftDeleteModal from "../../Components/Common/ShiftDeleteModal";
import { Widgets } from "./Widgets";
import {
  Scheduler,
  SchedulerData,
  ViewType,
  wrapperFun,
} from "roster-schedule";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { get_cookie } from "../../helpers/get_cookie";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function reducer(state, action) {
  switch (action.type) {
    case "INITIALIZE":
      return { showScheduler: true, viewModel: action.payload };
    case "UPDATE_SCHEDULER":
      return { ...state, viewModel: action.payload };
    case "REINITIALIZE":
      return { ...state, showScheduler: false };
    default:
      return state;
  }
}

let schedulerData;

const initialState = {
  showScheduler: false,
  viewModel: {},
};
const defaultStatusCounts = {
  Open: 0,
  Empty: 0,
  Assigned: 0,
  published: 0,
  unPublished: 0,
};

function Calendar() {
  const navigate = useNavigate(); // Create a useNavigate instance

  const location = useLocation();
  const [authUserId, setAuthUserId] = useState();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [users, setUsers] = useState([]);
  const view = "Day";
  const [weekStart, setWeekStart] = useState(1);
  const [data, setData] = useState([]);
  const [resourcesdata, setResources] = useState([]);
  const [eventsdata, setEventsdata] = useState([]);
  const [siteOpeningHours, setSiteOpeningHours] = useState([]);
  const [duration, setDuration] = useState("");
  const [notes, setNotes] = useState("");
  const today = new Date();
  const [activeDate, setActiveDate] = useState(null);
  const user_id = JSON.parse(get_cookie("authUser")).user_id;
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  const [selectedDays, setSelectedDays] = useState([]);
  const [EditShiftId, setEditShiftId] = useState([]);
  const [copyModal, setCopyModal] = useState(false);
  const [copyDayModal, setCopyDayModal] = useState(false);
  const [isAdvanced, setIsAdvanced] = useState(false);
  const [copyType, setCopyType] = useState("");
  const [selectedFromWeek, setSelectedFromWeek] = useState(null);
  const [selectedToWeek, setSelectedToWeek] = useState(null);
  const [selectedFromDay, setSelectedFromDay] = useState(null);
  const [selectedToDay, setSelectedToDay] = useState(null);
  const [areas, setAreas] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [isCopy, setIsCopy] = useState(false);
  const [progress, setProgress] = useState(0);
  const [responseReceived, setResponseReceived] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [deleteSpinner, setDeleteSpinner] = useState(false);

  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const headerId = location.state?.headerId;
  const siteId = location.state?.siteId;
  const userId = location.state?.userId;
  const restrictDate = location.state?.restrictDate;
  const [shiftStatusCounts, setShiftStatusCounts] =
    useState(defaultStatusCounts);
  const [siteOpening, setSiteOpening] = useState();
  const [siteClosing, setSiteClosing] = useState();
  console.log(siteId, "siteid");
  console.log(userId, "userid");
  console.log(restrictDate, "headerid");

  const pastelColors = [
    "#fd99a8", // Plum
    "#FFA07A", // LightSalmon
    "#FFD700", // Gold
    "#90df90", // PaleGreen
    "#8bdddd", // PaleTurquoise
    "#af91e3", // Lavender
    "#f791eb", // LightPink
  ];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [shiftMembers, setShiftMembers] = useState([]);
  const [ipEnabledBusiness, setIpEnabledBusiness] = useState("");
  const [zones, setZones] = useState([]);
  // const [sites, setSites] = useState([]);
  // const [selectedSite, setSelectedSite] = useState(null);
  const [shiftData, setShiftData] = useState([]);
  const [memberId, setMemberId] = useState("");
  const [memberIdError, setMemberIdError] = useState(null);
  const [zoneId, setZoneId] = useState("");
  const [zoneIdError, setZoneIdError] = useState("");
  const [start, setStart] = useState("");
  const [slot_id, setSlotId] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [totalHours, setTotalHours] = useState("");
  // const [activeIndex, setActiveIndex] = useState(null);
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [rosterHead, setRosterHead] = useState("");
  const [siteName, setSiteName] = useState("");
  // publish count
  const [publishCount, setPublishCount] = useState(0);
  const [totalShifts, setTotalShifts] = useState(0);
  const [publishResponse, setPublishResponse] = useState(null);
  const [unPublishedWorkers, setUnPublishedWorkers] = useState(null);
  const [toggleRepeatDays, setToggleRepeatDays] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen1, setDropdownOpen1] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isConfirmLoading, setIsConfirmLoader] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [coll2, setcoll2] = useState(false);

  // permissions
  const all_permissions = JSON.parse(get_cookie("permissions"));
  const [isChangeTime, setIsChangeTime] = useState(false);
  const [isChangeZone, setIsChangeZone] = useState(false);
  const [isChangeBreak, setIsChangeBreak] = useState(false);
  const [isChangeNote, setIsChangeNote] = useState(false);
  const [inputName, setInputName] = useState("");

  const view_shift = all_permissions?.includes("view_shifts");
  const create_shift = all_permissions?.includes("create_shifts");
  const edit_shift = all_permissions?.includes("edit_shifts");
  const delete_shift = all_permissions?.includes("delete_shifts");
  const publish_shift = all_permissions?.includes("publish_shifts");
  const [isCurrentWeek, setIsCurrentWeek] = useState(false);
  const allDays = [
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
    { value: "Sunday", label: "Sunday" },
  ];
  const [openProfiles, setOpenProfiles] = useState(
    Array(users.length).fill(false)
  );

  const [isInstantPayoutEnabled, setIsInstantPayoutEnabled] = useState(false);
  const [instantHourlyRate, setInstantHourlyRate] = useState("");
  const [hourlyRateError, setHourlyRateError] = useState("");

  const [isEditInstantPayoutEnabled, setEditIsInstantPayoutEnabled] =
    useState(false);
  const [editInstantHourlyRate, setEditInstantHourlyRate] = useState("");
  const [editHourlyRateError, setEditHourlyRateError] = useState("");

  // Handler for checkbox change
  const handleCheckboxChange = (event) => {
    setIsInstantPayoutEnabled(event.target.checked);
    // If unchecked, clear the hourly rate
    if (!event.target.checked) {
      setInstantHourlyRate("");
      setHourlyRateError(""); // Optionally clear the error as well
      setTotalAmount("");
      setTotalHours("");
    }
  };

  // Handler for checkbox change
  const handleEditCheckboxChange = (event) => {
    setEditIsInstantPayoutEnabled(event.target.checked);
    // If unchecked, clear the hourly rate
    if (!event.target.checked) {
      setEditInstantHourlyRate("");
      setEditHourlyRateError(""); // Optionally clear the error as well
      setTotalAmount("");
      setTotalHours("");
    }
  };
  // const handleHourlyRateChange = (e) => {
  //   const value = e.target.value;
  //   setInstantHourlyRate(value);
  //   setHourlyRateError("");
  // };
  const handleHourlyRateChange = (e) => {
    const value = e.target.value;
    console.log(value, "VALUE23");
    // // Check if the input is a valid number using a regex
    // const containsLetters = /[a-zA-Z]/.test(value);

    // if (containsLetters) {
    //     setHourlyRateError("Hourly Rate must be numeric.");
    //     return; // Exit the function early if the input is invalid
    // } else {
    //     setHourlyRateError(""); // Clear error message if input is valid
    // }
    // Use a regex to filter out unwanted characters
    const validValue = value
      .replace(/[^0-9.]/g, "") // Remove any non-numeric characters except for the dot
      .replace(/(\..*?)\..*/g, "$1") // Allow only one decimal point
      .replace(/^(\d*\.?\d{0,2})\d*$/, "$1"); // Limit to two decimal places

    setInstantHourlyRate(validValue);
    setHourlyRateError("");
  };

  const handleEditHourlyRateChange = (e) => {
    const value = e.target.value;
    console.log(value, "VALUE23");
    // // Check if the input is a valid number using a regex
    // const containsLetters = /[a-zA-Z]/.test(value);

    // if (containsLetters) {
    //     setHourlyRateError("Hourly Rate must be numeric.");
    //     return; // Exit the function early if the input is invalid
    // } else {
    //     setHourlyRateError(""); // Clear error message if input is valid
    // }
    // Use a regex to filter out unwanted characters
    const validValue = value
      .replace(/[^0-9.]/g, "") // Remove any non-numeric characters except for the dot
      .replace(/(\..*?)\..*/g, "$1") // Allow only one decimal point
      .replace(/^(\d*\.?\d{0,2})\d*$/, "$1"); // Limit to two decimal places
    setEditInstantHourlyRate(validValue);
    setEditHourlyRateError("");
    // if(validValue){
    //   console.log(validValue, 'VALIDVALUE');
    //   totalAmountCal();
    // }
  };

  useEffect(() => {
    let interval;

    if (isCopy) {
      setProgress(0);
      interval = setInterval(() => {
        setProgress((prev) => {
          if (prev < 90) {
            return prev + 1;
          } else {
            clearInterval(interval);
            return prev;
          }
        });
      }, 70); // Adjust the speed of the animation by changing the interval time
    }

    return () => clearInterval(interval);
  }, [isCopy]);

  useEffect(() => {
    console.log("Response rec useeffect,", progress, responseReceived);
    if (responseReceived && progress < 100) {
      const completeInterval = setInterval(() => {
        setProgress((prev) => {
          if (prev < 100) {
            return prev + 1;
          } else {
            clearInterval(completeInterval);
            setResponseReceived(false);
            return prev;
          }
        });
      }, 50);
    }
  }, [responseReceived]);

  useEffect(() => {
    console.log("Response pending useeffect,", progress, responseReceived);
    if (progress === 100 && isCopy) {
      setCopyDayModal(false);
      setCopyModal(false);
      setIsCopy(false);
      setIsAdvanced(false);
      //toast.success("Shifts copied successfully");
    }
  }, [progress, isCopy]);

  const customMultiSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      //borderColor: state.isFocused ? "#7F4BDA" : "#fff",
      boxShadow: "0 0 0 1px #f3f3f3",
      //minHeight: "40px",
      border: "0px !important",
      padding: "0px",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#7F4BDA" : provided.backgroundColor,
      color: state.isSelected ? "white" : provided.color,
      "&:hover": {
        backgroundColor: "#e8e6f7",
        color: "black",
      },
    }),
    multiValueGeneric: (provided) => ({
      ...provided,
      color: "#101010",
    }),
    multiValue: (provided) => ({
      ...provided,
      color: "black",
      backgroundColor: "#e8e6f7",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#7F4BDA",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingRight: "10px",
      padding: 0,
      color: "#7F4BDA",
      "&:hover": {
        color: "#7527CE",
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };

  const customSingleSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      // borderColor: state.isFocused ? "#7F4BDA" : "#fff",
      boxShadow: "0 0 0 1px #f3f3f3",

      border: "0px !important",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#7F4BDA" : provided.backgroundColor,
      color: state.isSelected ? "white" : provided.color,
      "&:hover": {
        backgroundColor: "#e8e6f7",
        color: "black",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingRight: "10px",
      padding: 0,
      color: "#7F4BDA",
      "&:hover": {
        color: "#7527CE",
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const toggleDropdown1 = () => {
    setDropdownOpen1(!dropdownOpen1);
  };
  // const handleCardClick = (index) => {
  //   setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  // };

  const toggleModal = () => {
    setBreaks([]);
    setIsModalOpen(!isModalOpen);
    if (!isModalOpen) {
      setIsChangeBreak(false);
      setIsChangeNote(false);
      setIsChangeTime(false);
      setIsChangeZone(false);
    }
  };
  const toggleCopyModal = () => {
    setCopyModal(!copyModal);
    setIsAdvanced(false);
    setProgress(0);
    setIsCopy(false);
    setResponseReceived(false);
  };
  const toggleCopyDayModal = () => {
    setCopyDayModal(!copyDayModal);
    setIsAdvanced(false);
    setProgress(0);
    setIsCopy(false);
    setResponseReceived(false);
  };

  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  const togglePublishModal = () => {
    setIsPublishModalOpen(!isPublishModalOpen);
    setPublishResponse(null);
  };

  const t_coll2 = () => {
    setcoll2(!coll2);
  };

  const handleDayClick = (value) => {
    const currentDate = moment();
    const clickedDate = moment().day(value);
    const isPastDate = clickedDate.isBefore(currentDate.startOf("day"));

    if (!isPastDate || value === "Sunday") {
      const updatedSelectedDays = selectedDays.includes(value)
        ? selectedDays.filter((day) => day !== value)
        : [...selectedDays, value];
      setSelectedDays(updatedSelectedDays);
    }
  };

  const isAllSelected = () => {
    const enabledDays = allDays
      .filter(
        (day) =>
          day.value !== "Sunday" &&
          !moment().day(day.value).isBefore(moment().startOf("day"))
      )
      .map((day) => day.value);
    return enabledDays.every((day) => selectedDays.includes(day));
  };

  const handleSelectAll = () => {
    const enabledDays = allDays
      .filter(
        (day) =>
          day.value !== "Sunday" &&
          !moment().day(day.value).isBefore(moment().startOf("day"))
      )
      .map((day) => day.value);

    const allEnabledSelected = enabledDays.every((day) =>
      selectedDays.includes(day)
    );
    const updatedSelectedDays = allEnabledSelected
      ? []
      : ["Sunday", ...enabledDays];
    setSelectedDays(updatedSelectedDays);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/get-user-details");
        // console.log(res);
        setAuthUserId(res.id);
        loadNewShiftData(res.id);
        // getSitesData(res.id);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log("input name", inputName);
    setIsChangeBreak(inputName === "break" ? true : false);
    setIsChangeNote(inputName === "note" ? true : false);
    setIsChangeTime(inputName === "time" ? true : false);
    setIsChangeZone(inputName === "zone" ? true : false);
  }, [inputName]);

  useEffect(() => {
    if (schedulerData) {
      schedulerData.config.siteStart = siteOpening;
      schedulerData.config.siteClose = siteClosing;
    }
    dispatch({ type: "INITIALIZE", payload: schedulerData });
    return () => dispatch({ type: "REINITIALIZE" });
  }, [siteOpening, siteClosing]);
  // Function to handle new event creation
  const handleNewEvent = (
    schedulerData,
    slotId,
    slotName,
    start,
    end,
    type,
    item
  ) => {
    setSlotId(slotId);
    setStart(start);
    const selectedWeekOption = zones.find(
      (option) => option.zone_name === slotName
    );
    setZoneId({ value: selectedWeekOption?.id, label: slotName });
    setFromTime(siteOpeningHours?.opening_time);
    setToTime(siteOpeningHours?.closing_time);
    // Open modal for event creation
    setNotes("");
    setMemberId("");
    setInstantHourlyRate("");
    setIsInstantPayoutEnabled(false);
    setEditInstantHourlyRate("");
    setEditIsInstantPayoutEnabled(false);
    setBreakType("Meal");
    setDuration("5");
    setMemberIdError(null);
    setTotalAmount("");
    setTotalHours("");
    const shift_date =
      start instanceof Date
        ? start.toISOString().split("T")[0]
        : start.split(" ")[0];
    const selectedDate = new Date(shift_date);
    const today = new Date();
    selectedDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    if (selectedDate < today) {
      toast.error("Error: Selected date cannot be in the past.");
    } else {
      toggleModal();
    }
  };

  const handleApply = () => {
    setIsButtonDisabled(true); // Disable the button when clicked
    setIsLoading(true);
    if (selectedDays.length === 0) {
      toast.error("Select at least one day to assign the shift.");
      setIsLoading(false);
      setIsButtonDisabled(false);
      return;
    }

    let workforce_id;
    if (memberId.value === "Open" || memberId.value === "Empty") {
      workforce_id = null;
    } else {
      workforce_id = memberId.value;
    }
    var status = memberId.value;
    if (!isNaN(memberId.value) && typeof memberId.value === "number") {
      status = "Assigned";
    }
    const shift_date =
      start instanceof Date
        ? start.toISOString().split("T")[0]
        : start.split(" ")[0];

    const isShiftDataEmpty = Array.isArray(shiftData) && shiftData.length === 0;

    var formData = {
      user_id: authUserId,
      business_id: business_id,
      id: isShiftDataEmpty ? null : shiftData?.id,
      shift_status: status,
      workforce_id: workforce_id,
      shift_date: isShiftDataEmpty ? shift_date : shiftData?.shift_date,
      shift_start_time: isShiftDataEmpty
        ? fromTime
        : shiftData?.shift_start_time,
      shift_end_time: isShiftDataEmpty ? toTime : shiftData?.shift_end_time,
      breaks: breaks,
      notes: isShiftDataEmpty ? notes : shiftData?.business_notes,
      selected_days: selectedDays,
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().endOf("isoWeek").format("YYYY-MM-DD"),
      header_id: headerId,
      roster_zone_id: zoneId.value,
    };

    if (isShiftDataEmpty) {
      if (isInstantPayoutEnabled && instantHourlyRate) {
        // Assuming `totalAmount` is in the format "$558.30" or "558.30"
        const formattedAmount = parseFloat(totalAmount.replace(/[$,]/g, "")); // Remove any '$' or ',' symbols
        formData.instant_hourly_rate = instantHourlyRate;
        formData.instant_payout_enabled = isInstantPayoutEnabled;
        formData.amt_pre_authorized = Math.round(formattedAmount * 100); // Convert to cents and round
      }
      // else {
      //   formData.instant_hourly_rate = 0.0; // Set to 0.0 for clarity
      //   formData.instant_payout_enabled = false; // Use consistent boolean or expected type
      //   formData.amt_pre_authorized = 0; // Ensure it is a number
      // }
      console.log("SAVE_SHIFT");
      axios
        .post("/repeat-for-days", formData)
        .then((response) => {
          // console.log(response, "RESPONSE");
          if (res.errors == 1) {
            toast.success(res.message);
            handleRepeatDaysclick();
            getCalendarData("Click");
            setIsButtonDisabled(false);
          } else {
            toast.error(res.message, { theme: "light" });
            // setToggleRepeatDays(toggleRepeatDays);
            setIsButtonDisabled(false);
            handleRepeatDaysclick();
            getCalendarData("Click");
          }
        })
        .catch((error) => {
          toast.error("Something went wrong while saving the shift...");
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      console.log(formData, "RFSD_EDIT");
      console.log("EDIT_SHIFT");

      if (isEditInstantPayoutEnabled && editInstantHourlyRate) {
        // Assuming `totalAmount` is in the format "$558.30" or "558.30"
        const formattedAmount = parseFloat(totalAmount.replace(/[$,]/g, "")); // Remove any '$' or ',' symbols
        formData.instant_hourly_rate = editInstantHourlyRate;
        formData.instant_payout_enabled = isEditInstantPayoutEnabled;
        formData.amt_pre_authorized = Math.round(formattedAmount * 100); // Convert to cents and round
      }
      // else {
      //   formData.instant_hourly_rate = 0.0; // Set to 0.0 for clarity
      //   formData.instant_payout_enabled = false; // Use consistent boolean or expected type
      //   formData.amt_pre_authorized = 0; // Ensure it is a number
      // }
      axios
        .post("/edit-shift", formData)
        .then((response) => {
          console.log(response, "RESPONSE");
          if (response.success === 200 || response.success === 300) {
            const formDataCopy = { ...formData };
            console.log(formDataCopy, "FORMDATACOPY");
            axios.post("/repeat-for-days", formDataCopy).then((res) => {
              if (res.errors == 1) {
                toast.success(res.message);
                handleRepeatDaysclick();
                getCalendarData("Click");
                setIsButtonDisabled(false);
              } else {
                toast.error(res.message, { theme: "light" });
                // setToggleRepeatDays(toggleRepeatDays);
                setIsButtonDisabled(false);
                handleRepeatDaysclick();
                getCalendarData("Click");
              }
            });
            //toast.success(response.message, { theme: "light" });
          } else {
            toast.error(response.message);
          }
        })
        .catch((error) => {
          toast.error("Something went wrong while editing shift...");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const loadNewShiftData = (userId) => {
    const formData = {
      user_id: userId,
      business_id: business_id,
      site_id: siteId,
    };
    axios
      .post("/add-new-shifts", formData)
      .then((res) => {
        setShiftMembers(res?.shift_members);
        setZones(res?.zones);
        setIpEnabledBusiness(res?.ip_enabled_business);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Add state for ready flag
  const [ready, setReady] = useState(true);
  // useEffect(() => {
  //   console.log(ready, memberId,fromTime, toTime, instantHourlyRate, isInstantPayoutEnabled,"TOTALAMOUNT");
  //   if (ready && memberId && fromTime && toTime) {
  //     totalAmountCal();
  //   }
  // }, [memberId, fromTime, toTime, ready]);
  useEffect(() => {
    console.log(
      ready,
      memberId,
      fromTime,
      toTime,
      instantHourlyRate,
      editInstantHourlyRate,
      isInstantPayoutEnabled,
      isEditInstantPayoutEnabled,
      "TOTALAMOUNT"
    );

    // Check if primary conditions are met or if only secondary conditions are met
    const primaryConditionsMet = ready && memberId && fromTime && toTime;
    // Define secondary conditions for instant payout with a valid hourly rate
    const secondaryConditionsMet = isInstantPayoutEnabled;
    const ternaryConditionsMet = isEditInstantPayoutEnabled;
    // &&
    // instantHourlyRate !== undefined &&
    // instantHourlyRate !== "" &&
    // instantHourlyRate > 0;

    // Call totalAmountCal if either set of conditions is met
    if (
      primaryConditionsMet ||
      secondaryConditionsMet ||
      ternaryConditionsMet
    ) {
      totalAmountCal();
    }
  }, [
    memberId,
    fromTime,
    toTime,
    ready,
    instantHourlyRate,
    isInstantPayoutEnabled,
    isEditInstantPayoutEnabled,
    editInstantHourlyRate,
  ]);

  const loadEditShiftData = (shift_id) => {
    setTotalAmount(""); // Clear previous amount
    setTotalHours(""); // Clear previous hours
    setEditInstantHourlyRate("");
    setEditIsInstantPayoutEnabled(false);
    setReady(false); // Set flag to false before loading starts
    const formData = {
      user_id: user_id,
      business_id: business_id,
      shift_id: shift_id,
      site_id: siteId,
    };
    setBreaks([]);
    axios
      .post("/get-edit-shifts", formData)
      .then((res) => {
        setFromTime(res.shiftdata[0].shift_start_time);
        setToTime(res.shiftdata[0].shift_end_time);
        setShiftMembers(res?.shift_members);
        setIpEnabledBusiness(res.ip_enabled_business);
        setZones(res?.zones);
        setShiftData(res?.shiftdata[0]);
        setEditIsInstantPayoutEnabled(
          res?.shiftdata[0].ip_enabled === "Y" ? true : false
        );
        setEditInstantHourlyRate(res?.shiftdata[0].hourly_rate);
        if (res?.shiftdata[0].workforce_id) {
          const selUser = res?.shift_members.filter(
            (member) => member.id === res?.shiftdata[0].workforce_id
          );
          setMemberId({
            value: parseInt(selUser[0].id),
            label: selUser[0].full_name,
            profile_picture: selUser[0].profile_picture,
          });
        } else {
          if (res?.shiftdata[0].shift_status == "Empty") {
            setMemberId({
              value: "Empty",
              label: "Empty Shift (Assign to someone later)",
              profile_picture: null,
            });
          } else {
            setMemberId({
              value: "Open",
              label: "Open Shift (Allow team members to claim)",
              profile_picture: null,
            });
          }
        }
        if (res.shift_breaks && res.shift_breaks.length > 0) {
          res.shift_breaks.map((data) => {
            var newBreak = {
              id: data.id,
              break_type: data.break_type,
              duration: data.duration,
            };
            //setBreaks([...breaks, newBreak]);
            setBreaks((prevBreaks) => [...prevBreaks, newBreak]);
          });
        } else {
          const newBreak = { break_type: breakType, duration: duration };
          //setBreaks([...breaks, newBreak]);
          setBreaks((prevBreaks) => [...prevBreaks, newBreak]);
        }
        setReady(true); // Set flag to true after data is loaded and state is updated
        // totalAmountCal();
        console.log(res?.shiftdata[0]);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // useEffect(() => {
  //   if (shiftData.length > 0) {
  //     setEditIsInstantPayoutEnabled(shiftData.ip_enabled === 'Y' ? true : false);
  //     setEditInstantHourlyRate(shiftData.hourly_rate || "");
  //   }
  // }, [shiftData]);

  const handleEditShift = (schedulerData, event) => {
    console.log(event, "event12313");
    const start = event.shift_date;
    const shift_date =
      start instanceof Date
        ? start.toISOString().split("T")[0]
        : start.split(" ")[0];
    const selectedDate = new Date(shift_date);
    const today = new Date();
    selectedDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    if (event.published == "Y" && selectedDate < today) {
      toast.error("Error: Selected date cannot be in the past.");
    } else if (event.published == "N" && selectedDate < today) {
      handleShowDeleteModal(2);
      // setDeleteModal(true);
      setDeletingId(event.id);
    } else {
      toggleEditModal();
      setEditShiftId(event.id);
      loadEditShiftData(event.id);
    }
  };
  function handleOptionChange(selectedOption) {
    setMemberId({ value: selectedOption.value, label: selectedOption.label });
  }
  useEffect(() => {
    getCalendarData();
    loadNewShiftData(userId);
  }, [headerId]);
  // const getSitesData = (userid) => {
  //   let formData = {
  //     user_id: userid,
  //     business_id:business_id,
  //   };
  //   axios
  //     .post("calendar-sites-dropdown", formData)
  //     .then((res) => {
  //       setSites(res);
  //       setSelectedSite(res[0].id);
  //     })
  //     .then((err) => console.log(err));
  // };
  const getCurrentWeekStartAndEndDates = () => {
    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - dayOfWeek);
    startOfWeek.setHours(0, 0, 0, 0); // Set time to midnight
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999); // Set time to end of the day
    return { startOfWeek, endOfWeek };
  };

  const getCalendarData = (identity = false, searchQuery) => {
    var dayData = {};
    let start_Date, endDate; // Declaring outside the if-else block
    if (identity === "Click") {
      const start = schedulerData?.startDate.$d;
      const end = schedulerData?.endDate.$d;
      start_Date = moment(start).format("YYYY-MM-DD");
      endDate = moment(end).format("YYYY-MM-DD");
    } else {
      if (restrictDate && new Date(restrictDate) < new Date(today)) {
        start_Date = moment(restrictDate).format("YYYY-MM-DD");
        endDate = moment(restrictDate).format("YYYY-MM-DD");
      } else {
        start_Date = moment().format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
      }
    }
    const { startOfWeek, endOfWeek } = getCurrentWeekStartAndEndDates();
    const startDateObj = new Date(start_Date);
    const endDateObj = new Date(endDate);

    const isWithinCurrentWeek =
      (startDateObj >= startOfWeek && startDateObj <= endOfWeek) ||
      (endDateObj >= startOfWeek && endDateObj <= endOfWeek) ||
      (startDateObj <= startOfWeek && endDateObj >= endOfWeek);

    setIsCurrentWeek(isWithinCurrentWeek);
    dayData = {
      user_id: userId,
      business_id: business_id,
      site_id: siteId,
      header_id: headerId,
      startDate: start_Date,
      endDate: endDate,
      search: searchQuery,
    };
    axios.post("calendar-data", dayData).then((res) => {
      setShiftStatusCounts(calculateShiftStatusCounts(res?.shifts));
      setResources(res.zones ? res.zones : []);
      setSiteOpeningHours(res.site_opening_hours);
      setWeekStart(res.week_starts);
      setUsers(res.users);
      // setRosterHead(res.roster_header_name[0]?.roster_name);
      setRosterHead(res.roster_header_name);
      // setSiteName(res.site_name[0]?.site_name);
      setEventsdata(res.shifts ? res.shifts : []);
      setIsLoading(false);
      setSiteClosing(res.site_opening_hours?.closing_time);
      setSiteOpening(res.site_opening_hours?.opening_time);
    });
  };
  const calculateShiftStatusCounts = (shifts) => {
    const newShiftStatusCounts = { ...defaultStatusCounts };
    shifts?.forEach((shift) => {
      if (shift.published === "Y") {
        newShiftStatusCounts["published"] += 1;
      }
      const publishedNShifts = shifts.filter(
        (shift) => shift.published === "N"
      );
      const countPublishedN = publishedNShifts.length;
      newShiftStatusCounts["unPublished"] = countPublishedN;
      const status = shift.shift_status;
      newShiftStatusCounts[status] += 1;
    });
    return newShiftStatusCounts;
  };

  const handleSearchInputChange = (e) => {
    const searchQuery = e.target.value;
    getCalendarData(false, searchQuery);
  };

  useEffect(() => {
    var parentElement = document.querySelector(".out-div"); // Select the parent element using a CSS class
    schedulerData = new SchedulerData(
      restrictDate && new Date(restrictDate) < new Date(today)
        ? moment(restrictDate).format("YYYY-MM-DD")
        : moment(today).format("YYYY-MM-DD"),

      ViewType.Day,
      false,
      false,
      {
        besidesWidth: window.innerWidth <= 1600 ? 100 : 350,
        schedulerWidth: parentElement.offsetWidth - 50,
        dayMaxEvents: 99,
        weekMaxEvents: 9669,
        monthMaxEvents: 9669,
        quarterMaxEvents: 6599,
        yearMaxEvents: 9956,
        customMaxEvents: 9965,
        restrictDate: restrictDate,
        eventItemPopoverTrigger: "hover",
        schedulerContentHeight: "100%",
        resourceViewEnabled: true,
        responsiveByParent: true,
        weekStart: 1,
        siteStart: siteOpening,
        siteClose: siteClosing,
        views: [
          {
            viewName: "Day",
            viewType: ViewType.Day,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: "Week",
            viewType: ViewType.Week,
            showAgenda: false,
            isEventPerspective: false,
          },
          // {
          //   viewName: "Month",
          //   viewType: ViewType.Month,
          //   showAgenda: false,
          //   isEventPerspective: true,
          // },
        ],
      }
    );
  }, []);

  useEffect(() => {
    var parentElement = document.querySelector(".out-div");
    schedulerData.config.schedulerWidth = parentElement.offsetWidth - 50;
    console.log(schedulerData, "data");

    dispatch({ type: "INITIALIZE", payload: schedulerData });

    return () => dispatch({ type: "REINITIALIZE" });
  }, [coll2]);

  useEffect(() => {
    schedulerData.setSchedulerLocale("en");
    schedulerData.setCalendarPopoverLocale("en");
    schedulerData.setResources(resourcesdata);
    schedulerData.setEvents(eventsdata);
    schedulerData.setUsers(users);
    setTimeout(() => {
      let startDate = schedulerData.startDate.$d;
      let endDate = schedulerData.endDate.$d;
      publishShiftDates(startDate, endDate);
    }, 1000);

    dispatch({ type: "INITIALIZE", payload: schedulerData });

    return () => dispatch({ type: "REINITIALIZE" });
  }, [resourcesdata, eventsdata]);

  const prevClick = (schedulerData) => {
    setTimeout(() => {
      let startDate = schedulerData.startDate.$d;
      let endDate = schedulerData.endDate.$d;
      publishShiftDates(startDate, endDate);
    }, 1000);
    schedulerData.prev();
    schedulerData.setEvents(eventsdata);
    dispatch({ type: "UPDATE_SCHEDULER", payload: schedulerData });
    getCalendarData("Click");
  };

  const nextClick = (schedulerData) => {
    setTimeout(() => {
      let startDate = schedulerData.startDate.$d;
      let endDate = schedulerData.endDate.$d;
      publishShiftDates(startDate, endDate);
    }, 1000);
    schedulerData.next();
    schedulerData.setEvents(eventsdata);
    dispatch({ type: "UPDATE_SCHEDULER", payload: schedulerData });
    getCalendarData("Click");
  };

  const onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    schedulerData.setEvents(eventsdata);
    dispatch({ type: "UPDATE_SCHEDULER", payload: schedulerData });
    getCalendarData("Click");
  };

  const publishShiftDates = (start, end) => {
    const startDate = moment().startOf("day");
    const endDate = moment().endOf("day");

    const filteredEvents = eventsdata.filter((event) => {
      const eventDate = moment(event.shift_date).startOf("day");
      const isDateInRange = eventDate.isSameOrAfter(startDate);
      const hasNonEmptyStatus = event.shift_status !== "Empty";
      const isNotPublished = event.published !== "Y";
      return isDateInRange && hasNonEmptyStatus && isNotPublished;
    });

    const totalShifts = filteredEvents.length > 0 ? filteredEvents : 0;
    setTotalShifts(totalShifts);

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set hours to 00:00:00:00 for accurate comparison

    const countPublished = filteredEvents.filter(
      (event) =>
        ((event.published === "N" && event.shift_status === "Assigned") ||
          event.shift_status === "Open") &&
        new Date(event.shift_date) >= today // Check if shift date is today or in the future
    ).length;

    setPublishCount(countPublished);
  };

  const handleAreaMultiSelect = (selectedOptions) => {
    setSelectedAreas(selectedOptions);
  };
  const publishShifts = () => {
    setIsConfirmLoader(true);
    const formData = {
      shifts: totalShifts,
      user_id: user_id,
      business_id: business_id,
    };
    axios
      .post("/publish-shifts", formData)
      .then((res) => {
        const responseData = res.responses;
        if (responseData) {
          setPublishResponse(responseData);
          console.log(responseData);
          setUnPublishedWorkers(responseData.error_worker_names);
          // responseData?.map((res)=>{
          //   console.log(res.worker_name)
          // })
          // togglePublishModal();
          getCalendarData("Click");
        }
      })
      .finally(() => {
        setIsConfirmLoader(false);
      });
  };

  const onViewChange = (schedulerData, view) => {
    const start = new Date();

    setTimeout(() => {
      let startDate = schedulerData.startDate.$d;
      let endDate = schedulerData.endDate.$d;

      publishShiftDates(startDate, endDate);
    }, 1000);

    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );
    schedulerData.setEvents(eventsdata);
    dispatch({ type: "UPDATE_SCHEDULER", payload: schedulerData });

    const secondsBetween = (date1, date2) => {
      const diff = Math.abs(date1.getTime() - date2.getTime());
      return diff / 1000;
    };
    getCalendarData("Click");
  };

  const ops1 = (schedulerData, event) => {
    const shiftStart = moment(event.start).format("HH:mm");
    const shiftEnd = moment(event.end).format("HH:mm");
    var postData = {
      id: event.id,
      user_id: user_id,
      business_id: business_id,
      workforce_id: event.user.id,
      shift_date: event.shift_date,
      start: shiftStart,
      end: shiftEnd,
      break_type: event.break_type,
      duration: event.duration,
      shift_notes: event.shift_notes,
      resource_id: event.resourceId,
      is_paid: event.is_paid,
    };
    axios.post("/edit-shift", postData).then((res) => {
      if (res.success == 200) {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };

  const ops2 = (schedulerData, event) => {
    alert(
      `You just executed ops2 to event: {id: ${event.id}, title: ${event.title}}`
    );
  };

  const updateEventStart = (schedulerData, event, newStart) => {
    if (
      window.confirm(
        `Do you want to adjust the start of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newStart: ${newStart}}`
      )
    ) {
      schedulerData.updateEventStart(event, newStart);
    }
    dispatch({ type: "UPDATE_SCHEDULER", payload: schedulerData });
  };

  const updateEventEnd = (schedulerData, event, newEnd) => {
    if (
      window.confirm(
        `Do you want to adjust the end of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newEnd: ${newEnd}}`
      )
    ) {
      schedulerData.updateEventEnd(event, newEnd);
    }
    dispatch({ type: "UPDATE_SCHEDULER", payload: schedulerData });
  };

  const moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    const shift_date =
      start instanceof Date
        ? start.toISOString().split("T")[0]
        : start.split(" ")[0];
    const selectedDate = new Date(shift_date);
    const today = new Date();
    selectedDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    if (selectedDate < today) {
      toast.error("Error: Selected date cannot be in the past.");
    } else {
      const zoneOption = zones.find((option) => option.zone_name === slotName);
      let selectedWeekOption;

      if (event.title && event.title !== "") {
        selectedWeekOption = shiftMembers.find(
          (option) => option.full_name === event.title
        );

        if (selectedWeekOption && selectedWeekOption !== "") {
          setMemberId({ value: selectedWeekOption.id, label: event.title });
        }
      }
      let workforce_id;
      let shift_status;

      if (selectedWeekOption && selectedWeekOption !== "") {
        if (
          selectedWeekOption.id === "Open" ||
          selectedWeekOption.id === "Empty"
        ) {
          workforce_id = null;
          shift_status = selectedWeekOption.id;
        } else {
          workforce_id = selectedWeekOption.id;
          shift_status = selectedWeekOption.id;
        }
      } else {
        workforce_id = "";
        shift_status = event.shift_status;
      }
      var status = shift_status;
      if (!isNaN(shift_status) && typeof shift_status === "number") {
        status = "Assigned";
      }
      const shiftStart = moment(event.start).format("HH:mm:ss");
      const shiftEnd = moment(event.end).format("HH:mm:ss");

      var formData = {
        user_id: user_id,
        business_id: business_id,
        roster_zone_id: zoneOption.id,
        shift_status: status,
        workforce_id: workforce_id,
        shift_date: shift_date,
        shift_start_time: shiftStart,
        shift_end_time: shiftEnd,
        breaks: event.shift_breaks,
        notes: event.shift_notes,
        id: event.id,
        type: "draganddrop",
      };

      if (event.ip_enabled === "Y" && event.instant_hourly_rate) {
        // Parse `amt_pre_authorized` to a float, ensuring it handles formats like "$10.60" or "10.60"
        const formattedAmount = parseFloat(
          String(event.amt_pre_authorized).replace(/[$,]/g, "")
        ); // Ensure it's a string before replacing

        formData.instant_hourly_rate = parseFloat(event.instant_hourly_rate); // Convert to a float for consistency
        formData.instant_payout_enabled = event.ip_enabled; // Assign directly as it's already "Y"
        formData.amt_pre_authorized = Math.round(formattedAmount * 100); // Convert to cents and round
      }

      axios
        .post("/edit-shift", formData)
        .then((response) => {
          if (response.success === 200) {
            toast.success(response.message, { theme: "light" });
            getCalendarData("Click");
          } else {
            toast.error(response.message);
          }
        })
        .catch((error) => {
          toast.error("Something went wrong...");
        });
    }
  };

  const openModal = () => {
    console.log(schedulerData.viewType, ViewType, " open modal");
    if (schedulerData.viewType == ViewType.Day) {
      setCopyDayModal(true);
    } else if (schedulerData.viewType == ViewType.Week) {
      setCopyModal(true);
    }
  };

  const convertDecimalToHoursMinutes = (decimalHours) => {
    var hours = Math.floor(decimalHours);
    var minutes = Math.round((decimalHours - hours) * 60);
    return hours + "h " + minutes + "m";
  };

  const onScrollLeft = (schedulerData, schedulerContent) => {
    if (schedulerData.ViewTypes === ViewType.Day) {
      schedulerData.prev();
      schedulerData.setEvents(eventsdata);
      dispatch({ type: "UPDATE_SCHEDULER", payload: schedulerData });
      schedulerContent.scrollLeft = 10;
    }
  };
  const onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewType.Day) {
      schedulerData.next();
      schedulerData.setEvents(eventsdata);
      dispatch({ type: "UPDATE_SCHEDULER", payload: schedulerData });
      schedulerContent.scrollLeft = maxScrollLeft - 10;
    }
  };

  const onScrollTop = () => console.log("onScrollTop");

  const onScrollBottom = () => console.log("onScrollBottom");

  const toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    dispatch({ type: "UPDATE_SCHEDULER", payload: schedulerData });
  };
  const generateTimeOptions = () => {
    const options = [];
    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 60; m += 15) {
        const hour = h < 10 ? `0${h}` : `${h}`;
        const minute = m === 0 ? "00" : `${m}`;
        const time = `${hour}:${minute}:00`;
        options.push({ label: formatTime(time), value: time });
      }
    }
    return options;
  };
  // Function to format time as AM/PM
  const formatTime = (time) => {
    const [hour, minute] = time.split(":");
    const formattedHour = parseInt(hour, 10) % 12 || 12;
    const period = parseInt(hour, 10) < 12 ? "AM" : "PM";
    return `${formattedHour}:${minute} ${period}`;
  };
  const timeOptions = generateTimeOptions();
  const [breakType, setBreakType] = useState("");
  //const [duration, setDuration] = useState(5);
  const [breaks, setBreaks] = useState([]);
  const [image, setImage] = useState("");
  const [firstLetter, setFirstLetter] = useState("");
  const [fromTimeError, setFromTimeError] = useState("");
  const [toTimeError, setToTimeError] = useState("");
  const [modal_assignModal, setmodal_assignModal] = useState(false);
  const [modal_assignModalOne, setmodal_assignModalOne] = useState(false);
  const handleAddBreak = () => {
    const newBreak = { break_type: breakType, duration: duration };
    setBreaks([...breaks, newBreak]);
    // Reset fields after adding a break
    setBreakType("Meal");
    setDuration("5");
  };
  function tog_assignModal() {
    setmodal_assignModal(!modal_assignModal);
  }
  function tog_assignModalOne() {
    setmodal_assignModalOne(!modal_assignModalOne);
  }

  const handleDeleteBreak = (index) => {
    const updatedBreaks = breaks.filter((_, i) => i !== index);
    setBreaks(updatedBreaks);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(memberId, instantHourlyRate, "SUBMIT SAVE");

    // Basic validations
    if (memberId === "" || memberId === null) {
      setMemberIdError("Please select a user.");
    } else {
      setMemberIdError("");
    }

    if (fromTime === "" || fromTime === null) {
      setFromTimeError("Please select shift start time.");
    } else {
      setFromTimeError("");
    }

    if (toTime === "" || toTime === null) {
      setToTimeError("Please select shift end time.");
    } else {
      setToTimeError("");
    }

    if (
      memberId === "" ||
      memberId === null ||
      fromTime === "" ||
      fromTime === null ||
      toTime === "" ||
      toTime === null
    ) {
      return; // Stop if any required field is missing
    }

    if (
      isInstantPayoutEnabled &&
      (!instantHourlyRate ||
        instantHourlyRate <= 0 ||
        instantHourlyRate === "" ||
        instantHourlyRate === "null")
    ) {
      // setHourlyRateError("Please enter a numeric hourly rate.");
      setHourlyRateError("Please enter an hourly rate (numeric).");
      return;
    } else {
      setHourlyRateError("");
    }

    // Check if Instant Payout is enabled and IP check is required
    if (isInstantPayoutEnabled && memberId) {
      const selectedMember = shiftMembers.find(
        (member) => member.id === memberId.value
      );
      console.log("Selected Member:", selectedMember); // Debug log

      // Show error only if `ip_enabled` is not "Y"
      if (
        selectedMember &&
        selectedMember.workforce_profile &&
        selectedMember.workforce_profile.ip_enabled !== "Y"
      ) {
        setMemberIdError("Worker is not IP-enabled; cannot assign to shift.");
        // setMemberIdError("This workforce user is not IP enabled and cannot be assigned to this shift.");
        console.log("IP Validation Failed: Submission stopped."); // Debug log
        return; // Stop submission if validation fails
      }
    }

    // Proceed with form submission if no errors
    const shift_date =
      start instanceof Date
        ? start.toISOString().split("T")[0]
        : start.split(" ")[0];

    let workforce_id;
    if (memberId.value === "Open" || memberId.value === "Empty") {
      workforce_id = null;
    } else {
      workforce_id = memberId.value;
    }

    const formData = {
      user_id: authUserId,
      business_id: business_id,
      roster_zone_id: zoneId.value,
      shift_status: memberId.value,
      workforce_id: workforce_id,
      shift_date: shift_date,
      shift_start_time: fromTime,
      shift_end_time: toTime,
      breaks: [...breaks, { break_type: breakType, duration: duration }],
      notes: notes,
      header_id: headerId,
    };

    // Conditionally add instant fields if they are set and isInstantPayoutEnabled is true
    if (isInstantPayoutEnabled && instantHourlyRate) {
      // Assuming `totalAmount` is in the format "$558.30" or "558.30"
      const formattedAmount = parseFloat(totalAmount.replace(/[$,]/g, "")); // Remove any '$' or ',' symbols
      formData.instant_hourly_rate = instantHourlyRate;
      formData.instant_payout_enabled = isInstantPayoutEnabled;
      formData.amt_pre_authorized = Math.round(formattedAmount * 100); // Convert to cents and round
    }
    axios
      .post("/save-shifts", formData)
      .then((response) => {
        if (response.error === 1) {
          toast.success(response.message, { theme: "light" });
          setIsModalOpen(false);
          getCalendarData("Click");
        } else {
          toast.error(response.message);
          setIsModalOpen(true);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong...");
      });
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   console.log(memberId, instantHourlyRate, 'SUBMIT SAVE');
  //   // if (
  //   //   isInstantPayoutEnabled &&
  //   //   (instantHourlyRate === "" || instantHourlyRate === null)
  //   // )

  //   if (memberId === "" || memberId === null) {
  //     setMemberIdError("please select user");
  //   } else {
  //     setMemberIdError("");
  //   }
  //   if (fromTime === "" || fromTime === null) {
  //     setFromTimeError("please select shift start time.");
  //   } else {
  //     setFromTimeError("");
  //   }
  //   if (toTime === "" || toTime === null) {
  //     setToTimeError("please select shift end time.");
  //   } else {
  //     setToTimeError("");
  //   }
  //   if (
  //     memberId === "" ||
  //     memberId === null ||
  //     fromTime === "" ||
  //     fromTime === null ||
  //     toTime === "" ||
  //     toTime === null
  //   ) {
  //     return;
  //   }

  //   if (
  //     isInstantPayoutEnabled &&
  //     (!instantHourlyRate ||
  //       instantHourlyRate <= 0 ||
  //       instantHourlyRate === "" ||
  //       instantHourlyRate === "null")
  //   )
  //    {
  //     setHourlyRateError("please enter an hourly rate");
  //     return;
  //   } else {
  //     setHourlyRateError("");
  //   }

  //   // Check if Instant Payout is enabled
  //   if (isInstantPayoutEnabled && memberId) {
  //     const selectedMember = shiftMembers.find((member) => member.id === memberId.value);

  //     // Validate if the selected member is IP enabled
  //     if (selectedMember && selectedMember.workforce_profile.ip_enabled !== "Y") {
  //       setMemberIdError("This workforce user is not IP enabled and cannot be assigned to this shift.");
  //       return; // Stop submission if validation fails
  //     }
  //     else{
  //       setMemberIdError("");
  //     }
  //   }
  //   const shift_date =
  //     start instanceof Date
  //       ? start.toISOString().split("T")[0]
  //       : start.split(" ")[0];
  //   let workforce_id;
  //   if (memberId.value === "Open" || memberId.value === "Empty") {
  //     workforce_id = null;
  //   } else {
  //     workforce_id = memberId.value;
  //   }
  //   var formData = {
  //     user_id: authUserId,
  //     business_id: business_id,
  //     roster_zone_id: zoneId.value,
  //     shift_status: memberId.value,
  //     workforce_id: workforce_id,
  //     shift_date: shift_date,
  //     shift_start_time: fromTime,
  //     shift_end_time: toTime,
  //     breaks: [...breaks, { break_type: breakType, duration: duration }],
  //     notes: notes,
  //     header_id: headerId,
  //   };
  //   axios
  //     .post("/save-shifts", formData)
  //     .then((response) => {
  //       if (response.error === 1) {
  //         toast.success(response.message, { theme: "light" });
  //         setIsModalOpen(false);
  //         getCalendarData("Click");
  //       } else {
  //         toast.error(response.message);
  //         setIsModalOpen(true);
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error("Something went wrong...");
  //     });
  // };
  const handleShiftUpdate = (event) => {
    event.preventDefault();

    // Check if Instant Payout is enabled and IP check is required
    if (isEditInstantPayoutEnabled && memberId) {
      const selectedMember = shiftMembers.find(
        (member) => member.id === memberId.value
      );
      console.log("Selected Member:", selectedMember); // Debug log

      // Show error only if `ip_enabled` is not "Y"
      if (
        selectedMember &&
        selectedMember.workforce_profile &&
        selectedMember.workforce_profile.ip_enabled !== "Y"
      ) {
        setMemberIdError("Worker is not IP-enabled; cannot assign to shift.");
        // setMemberIdError("This workforce user is not IP enabled and cannot be assigned to this shift.");
        console.log("IP Validation Failed: Submission stopped."); // Debug log
        return; // Stop submission if validation fails
      }
    }

    if (
      isEditInstantPayoutEnabled &&
      (!editInstantHourlyRate ||
        editInstantHourlyRate <= 0 ||
        editInstantHourlyRate === "" ||
        editInstantHourlyRate === "null")
    ) {
      // setHourlyRateError("Please enter a numeric hourly rate.");
      setEditHourlyRateError("Please enter an hourly rate (numeric).");
      return;
    } else {
      setEditHourlyRateError("");
    }

    const shift_date =
      start instanceof Date
        ? start.toISOString().split("T")[0]
        : start.split(" ")[0];

    let workforce_id;
    if (memberId.value === "Open" || memberId.value === "Empty") {
      workforce_id = null;
    } else {
      workforce_id = memberId.value;
    }

    var status = memberId.value;
    if (!isNaN(memberId.value) && typeof memberId.value === "number") {
      status = "Assigned";
    }
    var formData = {
      user_id: authUserId,
      business_id: business_id,
      id: shiftData.id,
      shift_status: status,
      workforce_id: workforce_id,
      shift_date: shiftData.shift_date,
      shift_start_time: shiftData.shift_start_time,
      shift_end_time: shiftData.shift_end_time,
      breaks: breaks,
      notes: shiftData.business_notes,
      roster_zone_id: shiftData.roster_zone_id,
    };
    console.log(isEditInstantPayoutEnabled, editInstantHourlyRate, "EDITSHIFT");
    // Conditionally add instant fields if they are set and isInstantPayoutEnabled is true
    if (isEditInstantPayoutEnabled && editInstantHourlyRate) {
      // Assuming `totalAmount` is in the format "$558.30" or "558.30"
      const formattedAmount = parseFloat(totalAmount.replace(/[$,]/g, "")); // Remove any '$' or ',' symbols
      formData.instant_hourly_rate = editInstantHourlyRate;
      formData.instant_payout_enabled = isEditInstantPayoutEnabled;
      formData.amt_pre_authorized = Math.round(formattedAmount * 100); // Convert to cents and round
    }
    // else {
    //   formData.instant_hourly_rate = 0.0; // Set to 0.0 for clarity
    //   formData.instant_payout_enabled = false; // Use consistent boolean or expected type
    //   formData.amt_pre_authorized = 0; // Ensure it is a number
    // }
    console.log(formData, "FORMDATA_EDIT");
    axios
      .post("/edit-shift", formData)
      .then((response) => {
        if (response.success == 200) {
          toast.success(response.message, { theme: "light" });
          getCalendarData("Click");
          setIsEditModalOpen(false);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong...");
      });
  };

  console.log(memberIdError, memberId, "isEditInstantPayoutEnabled");

  function handleMember(selectedOption) {
    setMemberId(selectedOption);
  }
  function handleZone(selectedOption) {
    setZoneId(selectedOption);
  }
  const startDate = new Date(start);
  const options = { weekday: "short", day: "2-digit", month: "short" };
  const formattedDate = startDate.toLocaleDateString("en-US", options);
  // const handleSelectChange = (e) => {
  //   const selectedValue = e.target.value;
  //   setToTime(selectedValue); // Update state
  //   totalAmountCal(selectedValue); // Call API with the selected value
  //   setToTimeError("");
  // };
  // const handleStartTime = (e) => {
  //   const selectedValue = e.target.value;
  //   setFromTime(selectedValue);
  //   totalAmountCal(selectedValue);
  //   setFromTimeError("");
  // };
  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setTotalAmount(""); // Clear previous amount
    setTotalHours(""); // Clear previous hours
    setToTime(selectedValue); // Update toTime state
    setToTimeError(""); // Clear any errors

    // Check if fromTime and selectedValue are valid
    if (fromTime && selectedValue) {
      setReady(true); // Set ready to true if both times are set
    }
  };
  const handleStartTime = (e) => {
    const selectedValue = e.target.value;
    setTotalAmount(""); // Clear previous amount
    setTotalHours(""); // Clear previous hours
    setFromTime(selectedValue); // Update fromTime state
    setFromTimeError(""); // Clear any errors

    // Check if toTime and selectedValue are valid
    if (toTime && selectedValue) {
      setReady(true); // Set ready to true if both times are set
    }
  };

  // useEffect(() => {
  //   console.log("hai2314");
  //   totalAmountCal();
  // }, [memberId, fromTime, toTime]);
  // Add state for ready flag

  // const totalAmountCal = (selectedValue) => {
  //   console.log(fromTime, toTime, instantHourlyRate, isInstantPayoutEnabled,"TIME1");
  //   setTotalAmount("");
  //   setTotalHours("");
  //   const shift_date =
  //     start instanceof Date
  //       ? start.toISOString().split("T")[0]
  //       : start.split(" ")[0];
  //   if (memberId === "" || memberId === null) {
  //     //  setMemberIdError("please select user");
  //   } else {
  //     setMemberIdError("");
  //   }
  //   if (fromTime === "" || fromTime === null) {
  //     setFromTimeError("please select shift start time.");
  //   } else {
  //     setFromTimeError("");
  //   }
  //   if (toTime === "" || toTime === null) {
  //     setToTimeError("please select shift end time.");
  //   } else {
  //     setToTimeError("");
  //   }
  //   if (
  //     memberId === "" ||
  //     memberId === null ||
  //     fromTime === "" ||
  //     fromTime === null ||
  //     toTime === "" ||
  //     toTime === null
  //   ) {
  //     return;
  //   }
  //   let workforce_id;
  //   if (memberId.value === "Open" || memberId.value === "Empty") {
  //     workforce_id = null;
  //   } else {
  //     workforce_id = memberId.value;
  //   }
  //   var formData = {
  //     user_id: authUserId,
  //     business_id: business_id,
  //     workforce_id: workforce_id,
  //     shift_date: shift_date,
  //     shift_start_time: fromTime,
  //     shift_end_time: toTime,
  //   };
  //   axios
  //     .post("/shift-total-amount-cal", formData)
  //     .then((response) => {
  //       if (response.error === 1) {
  //         setTotalAmount(response.total_amount);
  //         setTotalHours(response.totalHours);
  //       } else {
  //         toast.error(response.message);
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(error);
  //     });
  // };

  const totalAmountCal = (selectedValue) => {
    console.log(
      fromTime,
      toTime,
      editInstantHourlyRate,
      isEditInstantPayoutEnabled,
      "TIME1"
    );

    setTotalAmount("");
    setTotalHours("");

    const shift_date =
      start instanceof Date
        ? start.toISOString().split("T")[0]
        : start.split(" ")[0];

    // Check for memberId, fromTime, and toTime when only memberId is provided
    if (
      memberId &&
      (fromTime === "" || fromTime === null || toTime === "" || toTime === null)
    ) {
      setFromTimeError("Please select shift start time.");
      setToTimeError("Please select shift end time.");
      return;
    } else {
      setFromTimeError("");
      setToTimeError("");
    }
    console.log(isInstantPayoutEnabled, instantHourlyRate, "instantHourlyRate");
    // Check for instantHourlyRate and isInstantPayoutEnabled when they are provided
    if (
      isInstantPayoutEnabled &&
      (!instantHourlyRate ||
        instantHourlyRate <= 0 ||
        instantHourlyRate === "" ||
        instantHourlyRate === "null")
    ) {
      setHourlyRateError("Please enter an hourly rate (numeric).");
      // setHourlyRateError("Please enter a numeric hourly rate.");
      // return;
    } else {
      setHourlyRateError("");
    }

    if (
      isEditInstantPayoutEnabled &&
      (!editInstantHourlyRate ||
        editInstantHourlyRate <= 0 ||
        editInstantHourlyRate === "" ||
        editInstantHourlyRate === "null")
    ) {
      setEditHourlyRateError("Please enter an hourly rate (numeric).");
      // setHourlyRateError("Please enter a numeric hourly rate.");
      // return;
    } else {
      setEditHourlyRateError("");
    }

    // Build formData based on available inputs
    let workforce_id;
    if (memberId?.value === "Open" || memberId?.value === "Empty") {
      workforce_id = null;
    } else {
      workforce_id = memberId?.value;
    }

    const formData = {
      user_id: authUserId,
      business_id: business_id,
      workforce_id: workforce_id,
      shift_date: shift_date,
      shift_start_time: fromTime,
      shift_end_time: toTime,
    };

    // Conditionally add instant fields if they are set and isInstantPayoutEnabled is true
    if (isInstantPayoutEnabled && instantHourlyRate) {
      formData.instant_hourly_rate = instantHourlyRate;
      formData.instant_payout_enabled = isInstantPayoutEnabled;
      formData.instant_shift = "instant_shift";
    }

    if (isEditInstantPayoutEnabled && editInstantHourlyRate) {
      formData.instant_hourly_rate = editInstantHourlyRate;
      formData.instant_payout_enabled = isEditInstantPayoutEnabled;
      formData.instant_shift = "instant_edit_shift";
    }

    axios
      .post("/shift-total-amount-cal", formData)
      .then((response) => {
        if (response.error === 1) {
          setTotalAmount(response.total_amount);
          setTotalHours(response.totalHours);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  const CustomOption = ({ data, onSelect }) => {
    const { label, profile_picture } = data;
    const [firstName, lastName] = label.split(" "); // Split the full name into first name and last name
    const firstLetterFirstName = firstName.charAt(0).toUpperCase(); // Get the first letter of the first name
    const firstLetterLastName = lastName
      ? lastName.charAt(0).toUpperCase()
      : "";
    //const firstLetter = label.charAt(0).toUpperCase();
    const handleClick = () => {
      onSelect(data); // Call onSelect function with the data when clicked
    };
    return (
      <div
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={handleClick}
      >
        {profile_picture ? (
          <img
            src={profile_picture}
            alt={label}
            style={{
              width: "24px",
              height: "24px",
              borderRadius: "50%",
              marginRight: "8px",
            }}
          />
        ) : (
          <div
            style={{
              width: "24px",
              height: "24px",
              borderRadius: "50%",
              backgroundColor: "#ccc",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "8px",
            }}
          >
            {firstLetterFirstName +
              (firstLetterLastName ? firstLetterLastName : "")}
          </div>
        )}
        <span>{label}</span>
      </div>
    );
  };
  const handleSelect = (selectedOption) => {
    setMemberId(selectedOption);
    setImage(selectedOption.profile_picture);
    const [firstName, lastName] = selectedOption.label.split(" "); // Split the full name into first name and last name
    const firstLetterFirstName = firstName.charAt(0).toUpperCase(); // Get the first letter of the first name
    const firstLetterLastName = lastName
      ? lastName.charAt(0).toUpperCase()
      : "";
    setFirstLetter(
      firstLetterFirstName + (firstLetterLastName ? firstLetterLastName : "")
    );
  };
  const [currentItem, setCurrentItem] = useState(null);

  //Repeat for tomorrow
  const handleDropdownItemClick = (value) => {
    if (value === "Repeat for tomorrow") {
      repeatforTomorrow();
    } else if (value === "Repeat for tomorrow edit") {
      repeatforTomorrowEdit();
    } else if (value === "Repeat for specific days") {
      setIsButtonDisabled(false);
      setSelectedDays("");
      handleRepeatDaysclick();
    } else if (value === "Repeat for rest of the week") {
      repeatforRestofWeek();
    } else if (value === "Repeat for rest of the week edit") {
      repeatforRestofWeekEdit();
    } else if (value === "Delete Shift") {
      deleteShift();
    } else if (value === "Delete shift edit") {
      handleShowDeleteModal(1);
      // deleteShiftEdit();
      // setDeleteModal(true);
    }
  };
  const [formDataForTomorrow, setformDataForTomorrow] = useState("");
  const [formDataForTomorrowEdit, setformDataForTomorrowEdit] = useState("");
  const repeatforTomorrow = () => {
    //Repeat For tomorrow
    if (memberId === "" || memberId === null) {
      setMemberIdError("please select user");
    } else {
      setMemberIdError("");
    }
    if (fromTime === "" || fromTime === null) {
      setFromTimeError("please select shift start time.");
    } else {
      setFromTimeError("");
    }
    if (toTime === "" || toTime === null) {
      setToTimeError("please select shift end time.");
    } else {
      setToTimeError("");
    }
    if (
      memberId === "" ||
      memberId === null ||
      fromTime === "" ||
      fromTime === null ||
      toTime === "" ||
      toTime === null
    ) {
      return;
    }
    if (
      isInstantPayoutEnabled &&
      (!instantHourlyRate ||
        instantHourlyRate <= 0 ||
        instantHourlyRate === "" ||
        instantHourlyRate === "null")
    ) {
      // setHourlyRateError("Please enter a numeric hourly rate.");
      setHourlyRateError("Please enter an hourly rate (numeric).");
      return;
    } else {
      setHourlyRateError("");
    }

    // Check if Instant Payout is enabled and IP check is required
    if (isInstantPayoutEnabled && memberId) {
      const selectedMember = shiftMembers.find(
        (member) => member.id === memberId.value
      );
      console.log("Selected Member:", selectedMember); // Debug log

      // Show error only if `ip_enabled` is not "Y"
      if (
        selectedMember &&
        selectedMember.workforce_profile &&
        selectedMember.workforce_profile.ip_enabled !== "Y"
      ) {
        setMemberIdError("Worker is not IP-enabled; cannot assign to shift.");
        // setMemberIdError("This workforce user is not IP enabled and cannot be assigned to this shift.");
        console.log("IP Validation Failed: Submission stopped."); // Debug log
        return; // Stop submission if validation fails
      }
    }
    const shift_date =
      start instanceof Date
        ? start.toISOString().split("T")[0]
        : start.split(" ")[0];
    let workforce_id;
    if (memberId.value === "Open" || memberId.value === "Empty") {
      workforce_id = null;
    } else {
      workforce_id = memberId.value;
    }
    var formData = {
      user_id: authUserId,
      business_id: business_id,
      roster_zone_id: zoneId.value,
      shift_status: memberId.value,
      workforce_id: workforce_id,
      shift_date: shift_date,
      shift_start_time: fromTime,
      shift_end_time: toTime,
      breaks: breaks,
      notes: notes,
      type: "repeat",
      header_id: headerId,
    };
    console.log(formData, "FORMDATARFT");

    if (isInstantPayoutEnabled && instantHourlyRate) {
      // Assuming `totalAmount` is in the format "$558.30" or "558.30"
      const formattedAmount = parseFloat(totalAmount.replace(/[$,]/g, "")); // Remove any '$' or ',' symbols
      formData.instant_hourly_rate = instantHourlyRate;
      formData.instant_payout_enabled = isInstantPayoutEnabled;
      formData.amt_pre_authorized = Math.round(formattedAmount * 100); // Convert to cents and round
    }
    console.log(formData, "RFT");
    axios
      .post("/save-shifts", formData)
      .then((response) => {
        if (response.error === 1) {
          toast.success(response.message, { theme: "light" });
          setIsModalOpen(false);
          getCalendarData("Click");
        } else {
          toast.error(response.message);
          setIsModalOpen(true);
          if (
            response.message === "The worker is not available for the shift."
          ) {
            setformDataForTomorrow(formData);
            setIsModalOpen(false);
            tog_assignModal(true);
          }
        }
      })
      .catch((error) => {
        toast.error("Something went wrong...");
      });
  };

  const repeatforTomorrowEdit = () => {
    // Check if Instant Payout is enabled and IP check is required
    if (isEditInstantPayoutEnabled && memberId) {
      const selectedMember = shiftMembers.find(
        (member) => member.id === memberId.value
      );
      console.log("Selected Member:", selectedMember); // Debug log

      // Show error only if `ip_enabled` is not "Y"
      if (
        selectedMember &&
        selectedMember.workforce_profile &&
        selectedMember.workforce_profile.ip_enabled !== "Y"
      ) {
        setMemberIdError("Worker is not IP-enabled; cannot assign to shift.");
        // setMemberIdError("This workforce user is not IP enabled and cannot be assigned to this shift.");
        console.log("IP Validation Failed: Submission stopped."); // Debug log
        return; // Stop submission if validation fails
      }
    }

    if (
      isEditInstantPayoutEnabled &&
      (!editInstantHourlyRate ||
        editInstantHourlyRate <= 0 ||
        editInstantHourlyRate === "" ||
        editInstantHourlyRate === "null")
    ) {
      // setHourlyRateError("Please enter a numeric hourly rate.");
      setEditHourlyRateError("Please enter an hourly rate (numeric).");
      return;
    } else {
      setEditHourlyRateError("");
    }

    const shift_date =
      start instanceof Date
        ? start.toISOString().split("T")[0]
        : start.split(" ")[0];

    let workforce_id;
    if (memberId.value === "Open" || memberId.value === "Empty") {
      workforce_id = null;
    } else {
      workforce_id = memberId.value;
    }

    var status = memberId.value;
    if (!isNaN(memberId.value) && typeof memberId.value === "number") {
      status = "Assigned";
    }

    var formData = {
      user_id: authUserId,
      business_id: business_id,
      id: shiftData.id,
      roster_zone_id: shiftData.roster_zone_id,
      shift_status: status,
      workforce_id: workforce_id,
      shift_date: shiftData.shift_date,
      shift_start_time: shiftData.shift_start_time,
      shift_end_time: shiftData.shift_end_time,
      breaks: breaks,
      notes: shiftData.business_notes,
      type: "repeat",
      header_id: headerId,
    };

    console.log(isEditInstantPayoutEnabled, editInstantHourlyRate, "EDITSHIFT");

    // Conditionally add instant fields if they are set and isInstantPayoutEnabled is true
    if (isEditInstantPayoutEnabled && editInstantHourlyRate) {
      // Assuming `totalAmount` is in the format "$558.30" or "558.30"
      const formattedAmount = parseFloat(totalAmount.replace(/[$,]/g, "")); // Remove any '$' or ',' symbols
      formData.instant_hourly_rate = editInstantHourlyRate;
      formData.instant_payout_enabled = isEditInstantPayoutEnabled;
      formData.amt_pre_authorized = Math.round(formattedAmount * 100); // Convert to cents and round
    }
    // else {
    //   formData.instant_hourly_rate = 0.0; // Set to 0.0 for clarity
    //   formData.instant_payout_enabled = false; // Use consistent boolean or expected type
    //   formData.amt_pre_authorized = 0; // Ensure it is a number
    // }
    axios
      .post("/edit-shift", formData)
      .then((response) => {
        if (response.success === 200 || response.success === 300) {
          const formDataCopy = { ...formData };
          axios
            .post("/save-shifts", formDataCopy)
            .then((response) => {
              if (response.error === 1) {
                toast.success(response.message, { theme: "light" });
                setIsEditModalOpen(false);
              } else {
                toast.error(response.message);
                setIsEditModalOpen(true);
                if (
                  response.message ===
                  "The worker is not available for the shift."
                ) {
                  setformDataForTomorrowEdit(formDataCopy);
                  setIsEditModalOpen(false);
                  tog_assignModalOne(true);
                }
              }
            })
            .catch((error) => {
              toast.error("Something went wrong...");
            });
          //toast.success(response.message, { theme: "light" });
          getCalendarData("Click");
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong while editing shift...");
      });
  };

  const repeatforRestofWeek = () => {
    if (memberId === "" || memberId === null) {
      setMemberIdError("please select user");
    } else {
      setMemberIdError("");
    }
    if (fromTime === "" || fromTime === null) {
      setFromTimeError("please select shift start time.");
    } else {
      setFromTimeError("");
    }
    if (toTime === "" || toTime === null) {
      setToTimeError("please select shift end time.");
    } else {
      setToTimeError("");
    }
    if (
      memberId === "" ||
      memberId === null ||
      fromTime === "" ||
      fromTime === null ||
      toTime === "" ||
      toTime === null
    ) {
      return;
    }
    if (
      isInstantPayoutEnabled &&
      (!instantHourlyRate ||
        instantHourlyRate <= 0 ||
        instantHourlyRate === "" ||
        instantHourlyRate === "null")
    ) {
      // setHourlyRateError("Please enter a numeric hourly rate.");
      setHourlyRateError("Please enter an hourly rate (numeric).");
      return;
    } else {
      setHourlyRateError("");
    }

    // Check if Instant Payout is enabled and IP check is required
    if (isInstantPayoutEnabled && memberId) {
      const selectedMember = shiftMembers.find(
        (member) => member.id === memberId.value
      );
      console.log("Selected Member:", selectedMember); // Debug log

      // Show error only if `ip_enabled` is not "Y"
      if (
        selectedMember &&
        selectedMember.workforce_profile &&
        selectedMember.workforce_profile.ip_enabled !== "Y"
      ) {
        setMemberIdError("Worker is not IP-enabled; cannot assign to shift.");
        // setMemberIdError("This workforce user is not IP enabled and cannot be assigned to this shift.");
        console.log("IP Validation Failed: Submission stopped."); // Debug log
        return; // Stop submission if validation fails
      }
    }
    const shift_date =
      start instanceof Date
        ? start.toISOString().split("T")[0]
        : start.split(" ")[0];
    let workforce_id;
    if (memberId.value === "Open" || memberId.value === "Empty") {
      workforce_id = null;
    } else {
      workforce_id = memberId.value;
    }

    var formData = {
      user_id: authUserId,
      business_id: business_id,
      roster_zone_id: zoneId.value,
      shift_status: memberId.value,
      workforce_id: workforce_id,
      shift_date: shift_date,
      shift_start_time: fromTime,
      shift_end_time: toTime,
      breaks: breaks,
      notes: notes,
      header_id: headerId,
    };
    if (isInstantPayoutEnabled && instantHourlyRate) {
      // Assuming `totalAmount` is in the format "$558.30" or "558.30"
      const formattedAmount = parseFloat(totalAmount.replace(/[$,]/g, "")); // Remove any '$' or ',' symbols
      formData.instant_hourly_rate = instantHourlyRate;
      formData.instant_payout_enabled = isInstantPayoutEnabled;
      formData.amt_pre_authorized = Math.round(formattedAmount * 100); // Convert to cents and round
    }
    console.log(formData, "RFW");
    axios
      .post("/repeat-shift-for-week", formData)
      .then((response) => {
        // if (response.error === 1) {
        //   toast.success(response.message, { theme: "light" });
        //   setIsModalOpen(false);
        //   getCalendarData("Click");
        // } else {
        //   toast.error(response.message);
        //   setIsModalOpen(true);
        // }
        const { error, message } = response; // Destructure error and message from response.data

        if (error === 1) {
          toast.error(message, { theme: "light" }); // Error case
          setIsModalOpen(true); // Keep modal open
        } else {
          toast.success(message, { theme: "light" }); // Success case
          setIsModalOpen(false); // Close modal
          getCalendarData("Click"); // Refresh calendar data
        }
      })
      .catch((error) => {
        toast.error("Something went wrong...");
      });
  };

  const repeatforRestofWeekEdit = () => {
    // Check if Instant Payout is enabled and IP check is required
    if (isEditInstantPayoutEnabled && memberId) {
      const selectedMember = shiftMembers.find(
        (member) => member.id === memberId.value
      );
      console.log("Selected Member:", selectedMember); // Debug log

      // Show error only if `ip_enabled` is not "Y"
      if (
        selectedMember &&
        selectedMember.workforce_profile &&
        selectedMember.workforce_profile.ip_enabled !== "Y"
      ) {
        setMemberIdError("Worker is not IP-enabled; cannot assign to shift.");
        // setMemberIdError("This workforce user is not IP enabled and cannot be assigned to this shift.");
        console.log("IP Validation Failed: Submission stopped."); // Debug log
        return; // Stop submission if validation fails
      }
    }

    if (
      isEditInstantPayoutEnabled &&
      (!editInstantHourlyRate ||
        editInstantHourlyRate <= 0 ||
        editInstantHourlyRate === "" ||
        editInstantHourlyRate === "null")
    ) {
      // setHourlyRateError("Please enter a numeric hourly rate.");
      setEditHourlyRateError("Please enter an hourly rate (numeric).");
      return;
    } else {
      setEditHourlyRateError("");
    }

    const shift_date =
      start instanceof Date
        ? start.toISOString().split("T")[0]
        : start.split(" ")[0];

    let workforce_id;
    if (memberId.value === "Open" || memberId.value === "Empty") {
      workforce_id = null;
    } else {
      workforce_id = memberId.value;
    }

    if (!isNaN(memberId.value) && typeof memberId.value === "number") {
      memberId.value = "Assigned";
    }

    var formData = {
      user_id: authUserId,
      business_id: business_id,
      id: shiftData.id,
      roster_zone_id: shiftData.roster_zone_id,
      shift_status: memberId.value,
      workforce_id: workforce_id,
      shift_date: shiftData.shift_date,
      shift_start_time: shiftData.shift_start_time,
      shift_end_time: shiftData.shift_end_time,
      breaks: breaks,
      notes: shiftData.business_notes,
      header_id: headerId,
    };

    console.log(isEditInstantPayoutEnabled, editInstantHourlyRate, "EDITSHIFT");
    // Conditionally add instant fields if they are set and isInstantPayoutEnabled is true
    if (isEditInstantPayoutEnabled && editInstantHourlyRate) {
      // Assuming `totalAmount` is in the format "$558.30" or "558.30"
      const formattedAmount = parseFloat(totalAmount.replace(/[$,]/g, "")); // Remove any '$' or ',' symbols
      formData.instant_hourly_rate = editInstantHourlyRate;
      formData.instant_payout_enabled = isEditInstantPayoutEnabled;
      formData.amt_pre_authorized = Math.round(formattedAmount * 100); // Convert to cents and round
    }
    // else {
    //   formData.instant_hourly_rate = 0.0; // Set to 0.0 for clarity
    //   formData.instant_payout_enabled = false; // Use consistent boolean or expected type
    //   formData.amt_pre_authorized = 0; // Ensure it is a number
    // }
    console.log(formData, "FORMDATA_EDIT");

    axios
      .post("/edit-shift", formData)
      .then((response) => {
        if (response.success === 200 || response.success === 300) {
          axios
            .post("/repeat-shift-for-week", formData)
            .then((response) => {
              if (response.error === 1) {
                toast.success(response.message, { theme: "light" });
              } else {
                toast.error(response.message);
              }
            })
            .catch((error) => {
              toast.error("Something went wrong...");
            });
          //toast.success(response.message, { theme: "light" });
          getCalendarData("Click");
          setIsEditModalOpen(false);
        } else {
          toast.error(response.message);
          setIsEditModalOpen(true);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong...");
      });
  };

  const deleteShift = () => {
    setIsModalOpen(false);
  };
  const deleteShiftEdit = () => {
    setDeleteSpinner(true);
    var formData = {
      user_id: authUserId,
      business_id: business_id,
      id: currentItem && currentItem == 1 ? shiftData.id : deletingId,
    };
    console.log(formData, "formData");
    axios
      .post("/delete-shifts", formData)
      .then((response) => {
        if (response.error === 1) {
          getCalendarData("Click");
          setDeleteModal(false);
          toast.success(response.message, { theme: "light" });
          setIsEditModalOpen(false);
          setDeleteSpinner(false);
        } else {
          toast.error(response.message, { theme: "light" });
          setIsEditModalOpen(true);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong...");
      });
  };

  const handleShowDeleteModal = (itemId) => {
    setCurrentItem(itemId);
    setDeleteModal(true);
  };

  const handleRepeatDaysclick = () => {
    if (memberId === "" || memberId === null) {
      setMemberIdError("please select user");
      // setIsModalOpen(true);
      // setToggleRepeatDays(false);
    } else {
      setMemberIdError("");
    }
    if (fromTime === "" || fromTime === null) {
      setFromTimeError("please select shift start time.");
      // setIsModalOpen(true);
      // setToggleRepeatDays(false);
    } else {
      setFromTimeError("");
    }
    if (toTime === "" || toTime === null) {
      setToTimeError("please select shift end time.");
      // setIsModalOpen(true);
      // setToggleRepeatDays(false);
    } else {
      setToTimeError("");
    }
    if (
      memberId === "" ||
      memberId === null ||
      fromTime === "" ||
      fromTime === null ||
      toTime === "" ||
      toTime === null
    ) {
      return;
    }
    if (
      isInstantPayoutEnabled &&
      (!instantHourlyRate ||
        instantHourlyRate <= 0 ||
        instantHourlyRate === "" ||
        instantHourlyRate === "null")
    ) {
      // setHourlyRateError("Please enter a numeric hourly rate.");
      setHourlyRateError("Please enter an hourly rate (numeric).");
      return;
    } else {
      setHourlyRateError("");
    }

    // Check if Instant Payout is enabled and IP check is required
    if (isInstantPayoutEnabled && memberId) {
      const selectedMember = shiftMembers.find(
        (member) => member.id === memberId.value
      );
      console.log("Selected Member:", selectedMember); // Debug log

      // Show error only if `ip_enabled` is not "Y"
      if (
        selectedMember &&
        selectedMember.workforce_profile &&
        selectedMember.workforce_profile.ip_enabled !== "Y"
      ) {
        setMemberIdError("Worker is not IP-enabled; cannot assign to shift.");
        // setMemberIdError("This workforce user is not IP enabled and cannot be assigned to this shift.");
        console.log("IP Validation Failed: Submission stopped."); // Debug log
        return; // Stop submission if validation fails
      }
    }
    // Check if Instant Payout is enabled and IP check is required
    if (isEditInstantPayoutEnabled && memberId) {
      const selectedMember = shiftMembers.find(
        (member) => member.id === memberId.value
      );
      console.log("Selected Member:", selectedMember); // Debug log

      // Show error only if `ip_enabled` is not "Y"
      if (
        selectedMember &&
        selectedMember.workforce_profile &&
        selectedMember.workforce_profile.ip_enabled !== "Y"
      ) {
        setMemberIdError("Worker is not IP-enabled; cannot assign to shift.");
        // setMemberIdError("This workforce user is not IP enabled and cannot be assigned to this shift.");
        console.log("IP Validation Failed: Submission stopped."); // Debug log
        return; // Stop submission if validation fails
      }
    }

    if (
      isEditInstantPayoutEnabled &&
      (!editInstantHourlyRate ||
        editInstantHourlyRate <= 0 ||
        editInstantHourlyRate === "" ||
        editInstantHourlyRate === "null")
    ) {
      // setHourlyRateError("Please enter a numeric hourly rate.");
      setEditHourlyRateError("Please enter an hourly rate (numeric).");
      return;
    } else {
      setEditHourlyRateError("");
    }

    setToggleRepeatDays(!toggleRepeatDays);
    setIsEditModalOpen(false);
    setIsModalOpen(false);
  };

  const createEmptyShiftforTomorrow = () => {
    console.log(formDataForTomorrow, "formDataForTomorrow");
    axios
      .post("/repeat-for-tomorrow-for-empty", formDataForTomorrow)
      .then((response) => {
        if (response.error === 1) {
          toast.success(response.message, { theme: "light" });
          tog_assignModal(false);
          getCalendarData("Click");
        } else {
          toast.error(response.message);
          tog_assignModal(true);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong...");
      });
  };
  const createEmptyShiftforTomorrowEdit = () => {
    console.log(formDataForTomorrowEdit, "formDataForTomorrowEdit");
    axios
      .post("/repeat-for-tomorrow-for-empty", formDataForTomorrowEdit)
      .then((response) => {
        if (response.error === 1) {
          toast.success(response.message, { theme: "light" });
          tog_assignModalOne(false);
          getCalendarData("Click");
        } else {
          toast.error(response.message);
          tog_assignModalOne(true);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong...");
      });
  };
  const getPrevShiftOptions = () => {
    const start = schedulerData?.startDate.$d;
    const end = schedulerData?.endDate.$d;
    const schedulerStartDate = moment(start);
    const schedulerEndDate = moment(end);
    const options = [];

    // Get the start of the current year as January 1st
    const yearStart = moment(schedulerStartDate)
      .startOf("year")
      .startOf("week");

    // Start from the scheduler's start week
    let currentWeek = moment(schedulerStartDate).startOf("week");

    while (currentWeek.isSameOrAfter(yearStart, "week")) {
      const weekStart = currentWeek.clone().startOf("week");
      const weekEnd = currentWeek.clone().endOf("week");

      let weekLabel = `${weekStart.format("MMM Do")} - ${weekEnd.format(
        "MMM Do"
      )}`;

      // Customize label for the scheduler's start week
      if (currentWeek.isSame(moment(schedulerStartDate).startOf("week"))) {
        weekLabel = `This week, ${weekLabel}`;
      }
      // Customize label for the previous week
      else if (
        currentWeek.isSame(
          moment(schedulerStartDate).subtract(1, "week").startOf("week")
        )
      ) {
        weekLabel = `Last week, ${weekLabel}`;
      }

      const weekValue = `week${currentWeek.format("YYYY-W")}`;

      options.push({ label: weekLabel, value: weekValue });

      currentWeek.subtract(1, "week"); // Move to the previous week
    }
    console.log(options, " options console");
    return options;
  };
  const getPrevDayOptions = () => {
    const start = schedulerData?.startDate.$d;
    const schedulerStartDate = moment(start);
    const options = [];

    // Start from the scheduler's start date and go back 60 days
    let currentDay = moment(schedulerStartDate);

    for (let i = 0; i < 60; i++) {
      const dayLabel = currentDay.format("MMM Do, YYYY");
      const dayValue = `day${currentDay.format("YYYY-MM-DD")}`;

      options.push({ label: dayLabel, value: dayValue });

      currentDay.subtract(1, "day"); // Move to the previous day
    }

    console.log(options, " options console");
    return options;
  };
  const getNextShiftOptions = () => {
    const start = schedulerData?.startDate.$d;
    const end = schedulerData?.endDate.$d;
    const schedulerStartDate = moment(start);
    const schedulerEndDate = moment(end);
    const options = [];

    // Get the end of the current year
    const yearEnd = moment(schedulerStartDate).endOf("year");

    // Start from the next week of the scheduler's start date
    let currentWeek = moment(schedulerStartDate)
      .startOf("week")
      .add(1, "weeks");

    while (currentWeek.isSameOrBefore(yearEnd)) {
      const weekStart = currentWeek.clone().startOf("week");
      const weekEnd = currentWeek.clone().endOf("week");

      let weekLabel = `${weekStart.format("MMM Do")} - ${weekEnd.format(
        "MMM Do"
      )}`;

      // Customize label for the next week
      if (
        currentWeek.isSame(
          moment(schedulerStartDate).add(1, "week").startOf("week")
        )
      ) {
        weekLabel = `Next week, ${weekLabel}`;
      }

      const weekValue = `week${currentWeek.format("YYYY-W")}`; // Use formatted week number for unique value

      options.push({ label: weekLabel, value: weekValue });

      currentWeek.add(1, "weeks"); // Move to the next week
    }

    return options;
  };
  const getNextDayOptions = () => {
    const start = schedulerData?.startDate.$d;
    const schedulerStartDate = moment(start);
    const options = [];

    // Start from the next day of the scheduler's start date
    let currentDay = moment(schedulerStartDate).add(1, "day");

    for (let i = 0; i < 60; i++) {
      const dayLabel = currentDay.format("MMM Do, YYYY");
      const dayValue = `day${currentDay.format("YYYY-MM-DD")}`;

      options.push({ label: dayLabel, value: dayValue });

      currentDay.add(1, "day"); // Move to the next day
    }

    console.log(options, " options console");
    return options;
  };
  const prevWeekOptions = getPrevShiftOptions();
  const nextWeekOptions = getNextShiftOptions();
  const nextDayOptions = getNextDayOptions();
  const prevDayOptions = getPrevDayOptions();

  const handleDayCopy = () => {
    const start = schedulerData?.startDate.$d;
    const end = schedulerData?.endDate.$d;
    let start_date = moment(start).format("YYYY-MM-DD");
    let end_date = moment(end).format("YYYY-MM-DD");
    let formData;
    console.log(copyType);
    if (copyType == "advanced") {
      if (selectedAreas == null) {
        console.log("No Shifts found in selected day");
      } else {
        const selectedDate = extractDayDate(selectedFromDay?.label);
        const targetDate = extractDayDate(selectedToDay?.label);
        formData = {
          header_id: headerId,
          site_id: siteId,
          startDate: selectedDate,
          endDate: selectedDate,
          user_id: authUserId,
          business_id: business_id,
          type: copyType,
          to_date: targetDate,
          areas: selectedAreas,
        };
        console.log(formData, selectedDate, "advanced copy console");
      }
    } else {
      formData = {
        header_id: headerId,
        site_id: siteId,
        startDate: start_date,
        endDate: end_date,
        user_id: authUserId,
        business_id: business_id,
        type: copyType,
      };
    }

    axios
      .post("copy-day-shifts", formData)
      .then((res) => {
        if (res.error === 0) {
          toast.error(res.message);
          getCalendarData("Click");
          setCopyDayModal(false);
          //return;
        } else {
          setIsCopy(true);
          console.log(res);
          getCalendarData("Click");
          setResponseReceived(true);
          console.log(progress, "value of progress");
          toast.success(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setCopyDayModal(false);
        toast.error(err);
        setIsCopy(false);
        setResponseReceived(false);
      });
  };
  const handleCopy = () => {
    const start = schedulerData?.startDate.$d;
    const end = schedulerData?.endDate.$d;
    let start_date = moment(start).format("YYYY-MM-DD");
    let end_date = moment(end).format("YYYY-MM-DD");
    // let c_start = "2024-06-30";
    // let c_end = "2024-07-06";
    let formData;
    if (copyType == "advanced") {
      if (selectedAreas == null) {
        console.log("No Shifts found in selected week");
      } else {
        const fromWeekDates = extractWeekDates(selectedFromWeek?.label);
        const toWeekDates = extractWeekDates(selectedToWeek?.label);
        formData = {
          header_id: headerId,
          site_id: siteId,
          startDate: fromWeekDates.start,
          endDate: fromWeekDates.end,
          user_id: authUserId,
          business_id: business_id,
          type: copyType,
          to_start_date: toWeekDates.start,
          to_end_date: toWeekDates.end,
          areas: selectedAreas,
        };
      }
    } else {
      formData = {
        header_id: headerId,
        site_id: siteId,
        startDate: start_date,
        endDate: end_date,
        user_id: authUserId,
        business_id: business_id,
        type: copyType,
      };
    }

    axios
      .post("copy-shifts", formData)
      .then((res) => {
        if (res.error === 0) {
          toast.error(res.message);
          setCopyModal(false);
          getCalendarData("Click");
          console.log("hai234");
          //return;
        } else {
          setIsCopy(true);
          console.log(res);
          getCalendarData("Click");
          setResponseReceived(true);
          console.log(progress, "value of progress");
          toast.success(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setCopyModal(false);
        toast.error(err);
        setIsCopy(false);
        setResponseReceived(false);
      });
  };
  const extractWeekDates = (weekLabel) => {
    if (weekLabel != null) {
      console.log(weekLabel, "prevweek weekLabel");

      // Extract start and end dates from the label
      const dateRegex =
        /(\w+ \d{1,2}(?:st|nd|rd|th)?) - (\w+ \d{1,2}(?:st|nd|rd|th)?)/;
      const matches = weekLabel.match(dateRegex);

      if (matches) {
        const startLabel = matches[1];
        const endLabel = matches[2];

        const startOfWeek = moment(startLabel, "MMM Do").format("YYYY-MM-DD");
        const endOfWeek = moment(endLabel, "MMM Do").format("YYYY-MM-DD");

        console.log("week label is not null");
        return {
          start: startOfWeek,
          end: endOfWeek,
        };
      } else {
        console.error("Failed to parse dates from label");
      }
    } else {
      console.log("week label is null");
      const start = schedulerData?.startDate.$d;
      const end = schedulerData?.endDate.$d;
      const startOfWeek = moment(start).startOf("week").format("YYYY-MM-DD");
      const endOfWeek = moment(end).endOf("week").format("YYYY-MM-DD");
      return {
        start: startOfWeek,
        end: endOfWeek,
      };
    }
  };

  const extractDayDate = (dayLabel) => {
    if (dayLabel != null) {
      console.log(dayLabel, "dayLabel");

      // Extract the date from the label
      const dateRegex = /(\w+ \d{1,2}(?:st|nd|rd|th)?, \d{4})/;
      const matches = dayLabel.match(dateRegex);

      if (matches) {
        const dateStr = matches[1];
        const date = moment(dateStr, "MMM Do, YYYY").format("YYYY-MM-DD");

        console.log("day label is not null");
        return date;
      } else {
        console.error("Failed to parse date from label");
      }
    } else {
      console.log("day label is null");
      const date = moment(schedulerData?.startDate.$d).format("YYYY-MM-DD");
      return date;
    }
  };

  const getZonesForWeek = (e) => {
    console.log(e, "getZonesForWeek");

    const fromWeekDates = extractWeekDates(e?.label);
    console.log(e, selectedFromWeek, fromWeekDates, " prevweek formdata");
    let formData = {
      header_id: headerId,
      site_id: siteId,
      start_date: fromWeekDates.start,
      end_date: fromWeekDates.end,
      user_id: authUserId,
      business_id: business_id,
    };
    console.log(formData, "jenifer");
    axios
      .post("get-zones-for-week", formData)
      .then((res) => {
        console.log(res.zones);
        setAreas(res.zones);
        setSelectedAreas(res.zones);
      })
      .then((err) => {
        console.log(err);
      });
  };
  const getZonesForDay = (e) => {
    console.log(e, "getZonesForDay");

    const selectedDate = extractDayDate(e?.label);
    console.log(e, selectedDate, " zones formdata");
    let formData = {
      header_id: headerId,
      site_id: siteId,
      start_date: selectedDate,
      end_date: selectedDate,
      user_id: authUserId,
      business_id: business_id,
    };
    console.log(formData, "jenifer");
    axios
      .post("get-zones-for-day", formData)
      .then((res) => {
        console.log(res.zones);
        setAreas(res.zones);
        setSelectedAreas(res.zones);
      })
      .then((err) => {
        console.log(err);
      });
  };

  const handleAdvancedCopy = () => {
    console.log("advanced");
  };

  const itemsPerPage = 10;
  const totalItems = users.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const [activeIndexes, setActiveIndexes] = useState(-1);
  const [currentPage, setCurrentPage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);

  useEffect(() => {
    // Update displayed data based on the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, totalItems);
    setDisplayedData(users?.slice(startIndex, endIndex));
  }, [currentPage, users]);

  const handleCardClick = (index) => {
    setActiveIndexes(index === activeIndexes ? -1 : index);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      sendPageToBackend(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      sendPageToBackend(currentPage - 1);
    }
  };

  const sendPageToBackend = (page) => {
    const startIndex = (page - 1) * itemsPerPage;
  };

  const handlePreviousToPage = () => {
    navigate("/roster_lounge");
  };

  // Convert totalAmount to a numeric value
  const numericTotalAmount = parseFloat(totalAmount.replace("$", ""));

  return (
    <>
      <React.Fragment>
        <ShiftDeleteModal
          show={deleteModal}
          onDeleteClick={deleteShiftEdit}
          onCloseClick={() => setDeleteModal(false)}
          deleteSpinner={deleteSpinner}
        />
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col lg={12}>
                <Row className="ms-3">
                  <Widgets shiftStatusCounts={shiftStatusCounts} />
                </Row>

                <div className="d-flex justify-content-between mb-3 ms-3 align-items-center">
                  <Button
                    onClick={t_coll2}
                    color="primary"
                    style={{ cursor: "pointer", height: "40px" }}
                  >
                    <i className="ri-menu-2-line"></i>
                  </Button>
                  <h4> {rosterHead}</h4>
                  <div className="hstack gap-3 pe-2">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setProgress(0);
                        openModal();
                        console.log(prevWeekOptions, "prevweekoptions");
                        setSelectedFromWeek(prevWeekOptions[0]);
                        setSelectedFromDay(prevDayOptions[0]);
                        setSelectedToWeek(nextWeekOptions[0]);
                        setSelectedToDay(nextDayOptions[0]);
                        if (schedulerData.viewType == ViewType.Day) {
                          getZonesForDay();
                        } else if (schedulerData.viewType == ViewType.Week) {
                          getZonesForWeek();
                        }
                      }}
                    >
                      Copy shift <i className="bx bx-copy-alt pt-1"></i>
                    </button>
                    {eventsdata.length === 0 ? (
                      <span
                        className="badge badge-soft-danger p-2"
                        style={{
                          height: "2.3rem",
                          alignSelf: "center",
                          width: "100px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "0.8rem",
                        }}
                      >
                        No Shifts
                      </span>
                    ) : publishCount > 0 ? (
                      publish_shift && (
                        <span
                          style={{
                            height: "2.5rem",
                            alignSelf: "center",
                            width: "120px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-soft-success p-2 cursor-pointer "
                          onClick={
                            publish_shift ? togglePublishModal : () => {}
                          }
                        >
                          Publish {publishCount}{" "}
                          {publishCount > 1 ? "Shifts" : "Shift"}
                        </span>
                      )
                    ) : (
                      <span
                        className="badge badge-soft-success p-2"
                        style={{
                          height: "2.3rem",
                          alignSelf: "center",
                          width: "180px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "0.8rem",
                        }}
                      >
                        All Shifts Published
                      </span>
                    )}
                  </div>
                </div>

                <Row>
                  <Col
                    className={
                      coll2
                        ? "col-lg-3 custom-col-xxl-2 col-sm-9 ps-4 pe-0"
                        : "d-none pe-0"
                    }
                  >
                    <Card style={{ borderRadius: "20px" }}>
                      <CardBody>
                        {/* <div className="button-container">
                              <div id="logo"></div>
                            </div> */}
                        <div className="controls-container">
                          {/* <Calendar
                                ref={calendar}
                                // calendarMode="classic"
                                id="calendar"
                                scrollButtonsPosition={scrollButtonsPosition}
                                onChange={(e)=> {handleCalendarChange(e)}}
                                ></Calendar> */}
                          <div className="hstack gap-2 mb-3">
                            <Input
                              id="searchBar"
                              className="underlined rounded-pill"
                              placeholder="Search for people"
                              style={{
                                backgroundColor: "#F6F6F6",
                                border: "none",
                              }}
                              onChange={handleSearchInputChange}
                            ></Input>
                            {/* <Button className="btn-search-user bg-success rounded-pill">
                              {" "}
                              <i className="ri-user-search-line"></i>
                            </Button> */}
                          </div>

                          <Accordion id="default-accordion-example">
                            {displayedData?.map((data, index) => {
                              const isOpen =
                                openProfiles[
                                  index + (currentPage - 1) * itemsPerPage
                                ]; // Adjust index based on current page

                              const handleCardClick = () => {
                                setActiveIndexes((prevIndex) =>
                                  prevIndex === index ? null : index
                                );
                                const updatedProfiles = [...openProfiles]; // Create a copy of the state array
                                updatedProfiles[
                                  index + (currentPage - 1) * itemsPerPage
                                ] = !isOpen; // Toggle collapse state for the clicked user
                                setOpenProfiles(updatedProfiles); // Update the state with the new array
                              };

                              return (
                                <AccordionItem
                                  key={index + (currentPage - 1) * itemsPerPage}
                                  style={{ border: "none" }}
                                >
                                  <div
                                    className={`mb-2 mt-2 p-2 profile-card-cal cursor-pointer ${
                                      index +
                                        (currentPage - 1) * itemsPerPage ===
                                      activeIndexes
                                        ? "profile-card-active"
                                        : ""
                                    }`}
                                    onClick={() => handleCardClick(index)}
                                  >
                                    <div className="d-flex">
                                      <div
                                        className="avatar-xxs"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          height: "2.5rem",
                                          width: "2.5rem",
                                        }}
                                      >
                                        {data.s3url ? (
                                          <img
                                            src={data.s3url}
                                            alt=""
                                            style={{
                                              height: "100%",
                                              width: "100%",
                                            }}
                                            className="avatar-xxs rounded-circle"
                                          />
                                        ) : (
                                          <span
                                            className="avatar-title rounded-circle text-white"
                                            style={{
                                              padding: "4px",
                                              backgroundColor:
                                                pastelColors[
                                                  index % pastelColors.length
                                                ],
                                            }}
                                          >
                                            {data.first_name
                                              ? data.first_name
                                                  .split(" ") // Split the name into words
                                                  .map((word) =>
                                                    word.charAt(0).toUpperCase()
                                                  ) // Get the first character of each word
                                                  .join("") // Concatenate the initials together
                                              : ""}
                                            {data.last_name
                                              ? data.last_name
                                                  .split(" ") // Split the name into words
                                                  .map((word) =>
                                                    word.charAt(0).toUpperCase()
                                                  ) // Get the first character of each word
                                                  .join("") // Concatenate the initials together
                                              : ""}
                                          </span>
                                        )}
                                      </div>
                                      <div className="ms-2">
                                        <div className="emp_name">
                                          {data.first_name} {data.last_name}
                                        </div>
                                        <div className="emp_det text-muted">
                                          {convertDecimalToHoursMinutes(
                                            data.work_hours
                                          )}{" "}
                                          - ${data.desired_hourly_rate}
                                        </div>
                                      </div>
                                    </div>

                                    <Collapse
                                      isOpen={index === activeIndexes}
                                      className="accordion-collapse"
                                      id={`collapse-${index}`}
                                    >
                                      <div className="mt-2">
                                        <h6>Availability</h6>
                                        <div className="d-flex justify-content-between">
                                          {weekdays.map((weekday, idx) => (
                                            <span
                                              key={idx}
                                              className={`badge fs-12 cursor-default ${
                                                data.day_of_week?.includes(
                                                  weekday
                                                )
                                                  ? "badge-success-availability"
                                                  : "badge-dark-availability"
                                              }`}
                                            >
                                              {weekday.charAt(0)}
                                            </span>
                                          ))}
                                        </div>
                                      </div>
                                    </Collapse>
                                  </div>
                                </AccordionItem>
                              );
                            })}
                            <div className="d-flex justify-content-center align-items-center mt-3">
                              <button
                                className="btn btn-sm btn-primary me-2"
                                style={{ color: "white" }}
                                disabled={currentPage === 1}
                                onClick={handlePreviousPage}
                              >
                                &lt;
                              </button>
                              <span>
                                Page {currentPage} of {totalPages}
                              </span>
                              <button
                                className="btn btn-sm btn-primary ms-2"
                                disabled={currentPage === totalPages}
                                onClick={handleNextPage}
                                style={{ color: "white" }}
                              >
                                &gt;
                              </button>
                            </div>
                          </Accordion>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col
                    className={
                      coll2
                        ? "col-lg-9 custom-col-xxl-10 col-sm-9 pe-0 out-div "
                        : "col-12 pe-0 out-div "
                    }
                  >
                    {state.showScheduler && (
                      <Scheduler
                        schedulerData={state.viewModel}
                        prevClick={prevClick}
                        nextClick={nextClick}
                        onSelectDate={onSelectDate}
                        onViewChange={onViewChange}
                        restrictDate={restrictDate}
                        // viewEventClick={ops1}
                        viewEvent2Click={ops2}
                        viewEventHover={ops1}
                        viewEventText="Save"
                        // viewEvent2Text="Ops 2"
                        updateEventStart={updateEventStart}
                        updateEventEnd={updateEventEnd}
                        moveEvent={edit_shift ? moveEvent : () => {}}
                        eventItemClick={edit_shift ? handleEditShift : () => {}}
                        newEvent={create_shift ? handleNewEvent : () => {}}
                        onScrollLeft={onScrollLeft}
                        onScrollRight={onScrollRight}
                        onScrollTop={onScrollTop}
                        onScrollBottom={onScrollBottom}
                        toggleExpandFunc={toggleExpandFunc}
                        weekStart={0}
                        siteStart={siteOpening}
                        siteClose={siteClosing}
                        schedulerReload={() => getCalendarData("Click")}
                      />
                    )}
                  </Col>
                </Row>
                <Modal
                  className="zoomIn"
                  id="addSeller"
                  size="md"
                  isOpen={isModalOpen}
                  toggle={toggleModal}
                  centered
                >
                  <ModalBody>
                    <div className="hstack gap-2 align-items-center ">
                      <div className="">
                        {memberId.profile_picture ? (
                          <img
                            src={memberId.profile_picture}
                            alt=""
                            className="rounded-circle avatar-xxs d-inline-block"
                            style={{ height: "1.5rem", width: "1.5rem" }}
                          />
                        ) : firstLetter ? (
                          <span
                            className="rounded-circle avatar-xxs d-inline-block"
                            style={{
                              padding: "11px",
                              color: "white",
                              backgroundColor: "pink",
                              height: "1.5rem",
                              width: "1.5rem",
                            }}
                          >
                            {firstLetter}
                          </span>
                        ) : (
                          // <img
                          //   src={img13}
                          //   alt=""
                          //   className="rounded-circle avatar-xxs d-inline-block"
                          //   style={{ height: "1.5rem", width: "1.5rem" }}
                          // />
                          <i className="bx bxs-user  text-muted align-bottom fs-22"></i>
                        )}
                      </div>
                      {console.log(shiftMembers, "shiftMembers123")}
                      <div className="col-md-6">
                        <Select
                          value={memberId}
                          className={`${
                            memberIdError
                              ? "is-invalid my-select-container"
                              : "my-select-container"
                          }`}
                          classNamePrefix="my-select"
                          onChange={(selectedOption) => {
                            handleMember(selectedOption);
                            setMemberIdError("");
                          }}
                          options={
                            shiftMembers && Array.isArray(shiftMembers)
                              ? shiftMembers.map((item) => ({
                                  value: item.id,
                                  label: item.full_name,
                                  profile_picture: item.profile_picture,
                                }))
                              : []
                          }
                          /*components={{
                              Option: (props) => (
                                <CustomOption
                                  {...props}
                                  onSelect={handleSelect}
                                />
                              ),
                            }}*/
                        />
                      </div>
                      <Col md={5}>
                        {memberIdError && (
                          <div className="invalid-feedback-user">
                            {memberIdError}
                          </div>
                        )}
                      </Col>
                    </div>
                    <Row>
                      <Col md={12}>
                        <div style={{ maxWidth: "600px" }}>
                          {/* <FormGroup> */}
                          <Row className="align-items-center mt-3">
                            {/* Enable Instant Payout Checkbox */}
                            <Col xs="auto">
                              {/* <Label check> */}
                              <Label>
                                {/* <Input
                                  type="checkbox"
                                  checked={isInstantPayoutEnabled}
                                  onChange={handleCheckboxChange}
                                  style={{
                                    verticalAlign: "middle",
                                    marginBottom: "2%",
                                  }}
                                /> */}
                                <Input
                                  type="checkbox"
                                  checked={isInstantPayoutEnabled}
                                  onChange={handleCheckboxChange}
                                  disabled={ipEnabledBusiness === "N"}
                                  style={{
                                    verticalAlign: "middle",
                                    marginBottom: "2%",
                                    border: "1px solid #878a99",
                                    cursor:
                                      ipEnabledBusiness === "N"
                                        ? "not-allowed"
                                        : "pointer",
                                    pointerEvents:
                                      ipEnabledBusiness === "N"
                                        ? "none"
                                        : "auto",
                                  }}
                                />{" "}
                                <span
                                  className={
                                    ipEnabledBusiness == "N" ? "text-muted" : ""
                                  }
                                  style={{ marginLeft: "15px" }}
                                >
                                  Enable Instant Payout
                                </span>{" "}
                                {ipEnabledBusiness === "N" ? (
                                  <span id="info-icon">
                                    <img src={info} height={20} alt="Info" />
                                    <UncontrolledTooltip
                                      placement="right"
                                      target="info-icon"
                                    >
                                      Instant payout is not enabled for this
                                      business account.
                                    </UncontrolledTooltip>
                                  </span>
                                ) : null}
                              </Label>
                            </Col>

                            {/* Hourly Rate Field (conditionally displayed) */}
                            {isInstantPayoutEnabled && (
                              <Col xs="auto">
                                <div className="input-group">
                                  <div className="input-group-text ">$</div>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    value={instantHourlyRate}
                                    onChange={handleHourlyRateChange}
                                    placeholder="Enter Hourly Rate"
                                    min="0" // Ensures no negative values
                                    // step="0.01"
                                  >
                                    ${" "}
                                  </Input>
                                </div>
                                {hourlyRateError && (
                                  <div
                                    style={{
                                      color: "#e13c3c",
                                      fontSize: "0.875em",
                                    }}
                                  >
                                    {hourlyRateError}
                                  </div>
                                )}
                              </Col>
                            )}
                          </Row>
                          {/* </FormGroup> */}
                        </div>
                      </Col>
                    </Row>

                    <hr className="mt-0 mb-1 calendar-hr"></hr>
                    <div className="hstack gap-3">
                      <i className="ri-calendar-fill text-muted align-bottom fs-18"></i>{" "}
                      <Col md={5} lg={4}>
                        <label className="text-muted mb-0">
                          {formattedDate}
                        </label>
                      </Col>
                    </div>
                    <div className="hstack gap-3 mt-2">
                      <i className="ri-time-fill text-primary align-bottom fs-18"></i>{" "}
                      {isChangeTime ? (
                        <div className="hstack gap-3">
                          <select
                            className={`form-select ${
                              fromTimeError ? "is-invalid" : ""
                            }`}
                            value={fromTime}
                            onChange={handleStartTime}
                          >
                            {timeOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          {fromTimeError && (
                            <div className="invalid-feedback">
                              {fromTimeError}
                            </div>
                          )}
                          <t> - </t>
                          <select
                            className={`form-select ${
                              toTimeError ? "is-invalid" : ""
                            }`}
                            value={toTime}
                            onChange={handleSelectChange}
                          >
                            {timeOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          {toTimeError && (
                            <div className="invalid-feedback">
                              {toTimeError}
                            </div>
                          )}
                        </div>
                      ) : (
                        <Col md={5} lg={4} className="cursor-pointer">
                          <div
                            className="text-primary"
                            onDoubleClick={() => {
                              setIsChangeTime(true);
                              setInputName("time");
                            }}
                          >
                            {fromTime} - {toTime}
                          </div>
                        </Col>
                      )}
                    </div>
                    <div className="hstack gap-3 mt-2">
                      <i className="ri-record-circle-fill text-primary align-bottom fs-18"></i>{" "}
                      <Col md={5} lg={4}>
                        {isChangeZone ? (
                          <>
                            <Select
                              value={zoneId} // Find the option object with matching id
                              className={`${zoneIdError ? "is-invalid" : ""}`}
                              onChange={(selectedOption) => {
                                handleZone(selectedOption);
                                setZoneIdError("");
                              }}
                              options={
                                zones && Array.isArray(zones)
                                  ? zones.map((item) => ({
                                      value: item.id,
                                      label: item.zone_name,
                                    }))
                                  : []
                              }
                            />
                            {zoneIdError && (
                              <div className="invalid-feedback">
                                {zoneIdError}
                              </div>
                            )}
                          </>
                        ) : (
                          <div
                            className="text-primary cursor-pointer"
                            onDoubleClick={() => {
                              setIsChangeZone(true);
                              setInputName("zone");
                            }}
                          >
                            {zoneId ? zoneId.label : "Select Zone"}
                          </div>
                        )}
                      </Col>
                    </div>
                    <div className="mt-2">
                      {isChangeBreak ? (
                        <>
                          <div className="hstack gap-3">
                            <i className="ri-cup-fill  text-primary align-bottom fs-18"></i>{" "}
                            <div className="hstack gap-2">
                              <Col md={4}>
                                {" "}
                                <select
                                  className="form-select"
                                  value={breakType}
                                  onChange={(e) => setBreakType(e.target.value)}
                                >
                                  <option value="">Select Break Type</option>
                                  <option value="Meal">Meal Break</option>
                                  <option value="Rest">Rest Break</option>
                                </select>
                              </Col>
                              <Col md={4}>
                                {" "}
                                <select
                                  className="form-select"
                                  value={duration}
                                  onChange={(e) =>
                                    setDuration(parseInt(e.target.value))
                                  }
                                >
                                  <option value="">Select Break minutes</option>
                                  {[...Array(48)].map((_, i) => (
                                    <option key={i} value={(i + 1) * 5}>
                                      {(i + 1) * 5} mins
                                    </option>
                                  ))}
                                </select>
                              </Col>

                              <button
                                onClick={handleAddBreak}
                                className="btn btn-ghost-success"
                              >
                                Add Break
                              </button>
                            </div>
                          </div>
                          <div>
                            {console.log(breaks, "breaks123")}
                            {breaks?.map((breakItem, index) => (
                              <div className="hstack gap-3" key={index}>
                                <i className="ri-cup-fill  text-primary align-bottom fs-18"></i>{" "}
                                <div className="hstack gap-2">
                                  <Col md={4}>
                                    <select
                                      className="form-select"
                                      value={breakItem.break_type}
                                      onChange={(e) => {
                                        const newBreaks = [...breaks];
                                        newBreaks[index].break_type =
                                          e.target.value;
                                        setBreaks(newBreaks);
                                      }}
                                    >
                                      <option value="">
                                        Select Break Type
                                      </option>
                                      <option value="Meal">Meal Break</option>
                                      <option value="Rest">Rest Break</option>
                                    </select>
                                  </Col>
                                  <Col md={4}>
                                    <select
                                      className="form-select"
                                      value={breakItem.duration}
                                      onChange={(e) => {
                                        const newBreaks = [...breaks];
                                        newBreaks[index].duration =
                                          e.target.value;
                                        setBreaks(newBreaks);
                                      }}
                                    >
                                      <option value="">
                                        Select Break minutes
                                      </option>
                                      {[...Array(48)].map((_, i) => (
                                        <option key={i} value={(i + 1) * 5}>
                                          {(i + 1) * 5} mins
                                        </option>
                                      ))}
                                    </select>
                                  </Col>

                                  <button
                                    onClick={() => handleDeleteBreak(index)}
                                    className="btn btn-ghost-dark"
                                  >
                                    <i className="ri-delete-bin-6-line text-danger align-bottom fs-18"></i>{" "}
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <div className="hstack gap-3">
                          <i className="ri-cup-fill  text-primary align-bottom fs-18"></i>{" "}
                          <Col md={5} lg={4}>
                            <div
                              className="text-primary cursor-pointer"
                              onDoubleClick={() => {
                                setIsChangeBreak(true);
                                setInputName("break");
                              }}
                            >
                              {duration +
                                " mins" +
                                " of " +
                                breakType +
                                " break"}
                            </div>
                          </Col>
                        </div>
                      )}
                    </div>
                    <div className="hstack gap-3 mt-2">
                      <i className="ri-chat-1-fill text-muted align-bottom fs-18"></i>{" "}
                      {isChangeNote ? (
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea5"
                          rows="3"
                          value={notes}
                          onChange={(e) => setNotes(e.target.value)}
                        ></textarea>
                      ) : (
                        <Col md={5} lg={4} className="cursor-pointer">
                          <div
                            className="text-muted"
                            onDoubleClick={() => {
                              setIsChangeNote(true);
                              setInputName("note");
                            }}
                          >
                            {notes ? notes : "Add a note"}
                          </div>
                        </Col>
                      )}
                    </div>

                    <hr className="mt-1 mb-2 calendar-hr"></hr>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className=" vstack  align-items-start">
                        <Label
                          htmlFor="billinginfo-lastName"
                          className="form-label mb-0 text-muted"
                        >
                          Total
                        </Label>
                        <div>
                          {totalHours && totalAmount ? (
                            <h6
                              className="mb-0"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span>
                                {totalHours}Hrs - {totalAmount}
                              </span>
                              {isInstantPayoutEnabled &&
                                numericTotalAmount > 0 && (
                                  <span
                                    style={{
                                      fontSize: "10px",
                                      color: "#878a99",
                                      marginLeft: "4px",
                                    }}
                                  >
                                    (includes processing fee)
                                  </span>
                                )}
                            </h6>
                          ) : null}
                        </div>
                      </div>

                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary"
                          onClick={handleSubmit}
                        >
                          Save
                        </button>
                        <UncontrolledDropdown
                          isOpen={dropdownOpen}
                          toggle={toggleDropdown}
                        >
                          <DropdownToggle
                            className="btn btn-sm bg-soft-primary m-auto text-primary pt-2 fs-14 "
                            style={{ border: "none" }}
                          >
                            <i className="bx bx-dots-horizontal-rounded"></i>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() =>
                                handleDropdownItemClick("Repeat for tomorrow")
                              }
                            >
                              Repeat for tomorrow
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                handleDropdownItemClick(
                                  "Repeat for rest of the week"
                                )
                              }
                            >
                              Repeat for rest of the week
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                handleDropdownItemClick(
                                  "Repeat for specific days"
                                );
                              }}
                              disabled={!isCurrentWeek}
                            >
                              Repeat for specific days
                            </DropdownItem>
                            {/* <DropdownItem
                              onClick={() =>
                                handleDropdownItemClick("Send Shift")
                              }
                            >
                              Send Shift
                            </DropdownItem> */}
                            {/* <DropdownItem
                              onClick={() =>
                                handleDropdownItemClick("Delete Shift")
                              }
                            >
                              Delete shift
                            </DropdownItem> */}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                  </ModalBody>
                </Modal>
                <Modal
                  className="zoomIn"
                  id="editShift"
                  size="md"
                  isOpen={isEditModalOpen}
                  toggle={toggleEditModal}
                  centered
                >
                  <ModalBody>
                    <Row>
                      <div className="hstack gap-3 align-items-center ps-2  ">
                        <div className="">
                          <img
                            src={memberId.profile_picture || img13}
                            alt=""
                            className="rounded-circle avatar-xxs d-inline-block"
                            style={{ height: "1.7rem", width: "1.7rem" }}
                          />
                        </div>

                        <div className="col-md-6">
                          <Select
                            value={memberId}
                            className={`${memberIdError ? "is-invalid" : ""}`}
                            onChange={(selectedOption) => {
                              handleMember(selectedOption);
                              setMemberIdError("");
                            }}
                            options={
                              shiftMembers && Array.isArray(shiftMembers)
                                ? shiftMembers.map((item) => ({
                                    value: item.id,
                                    label: item.full_name,
                                    profile_picture: item.profile_picture,
                                  }))
                                : []
                            }
                          />
                        </div>
                        {/* <div className="col-md-4">
                          {memberIdError && (
                            <div className="invalid-feedback">
                              {memberIdError}
                            </div>
                          )}
                        </div> */}
                        <Col md={4}>
                          {memberIdError && (
                            <div className="invalid-feedback-user">
                              {memberIdError}
                            </div>
                          )}
                        </Col>
                      </div>
                      {/* <Col md={5}>
                        {memberIdError && (
                          <div className="invalid-feedback-user">
                            {memberIdError}
                          </div>
                        )}
                      </Col> */}
                      <Row>
                        <Col md={12}>
                          <div style={{ maxWidth: "600px" }}>
                            {/* <FormGroup> */}
                            <Row className="align-items-center mt-3">
                              {/* Enable Instant Payout Checkbox */}
                              <Col xs="auto">
                                {/* <Label check> */}
                                <Label>
                                  {" "}
                                  {/* edit */}
                                  <Input
                                    type="checkbox"
                                    checked={isEditInstantPayoutEnabled}
                                    onChange={handleEditCheckboxChange}
                                    // style={{
                                    //   verticalAlign: "middle",
                                    //   marginBottom: "2%",
                                    // }}
                                    disabled={ipEnabledBusiness === "N"}
                                    style={{
                                      verticalAlign: "middle",
                                      marginBottom: "2%",
                                      border: "1px solid #878a99",
                                      cursor:
                                        ipEnabledBusiness === "N"
                                          ? "not-allowed"
                                          : "pointer",
                                      pointerEvents:
                                        ipEnabledBusiness === "N"
                                          ? "none"
                                          : "auto",
                                    }}
                                  />{" "}
                                  <span
                                    className={
                                      ipEnabledBusiness == "N"
                                        ? "text-muted"
                                        : ""
                                    }
                                    style={{ marginLeft: "15px" }}
                                  >
                                    Enable Instant Payout
                                  </span>{" "}
                                  {ipEnabledBusiness === "N" ? (
                                    <span id="info-icon">
                                      <img src={info} height={20} alt="Info" />
                                      <UncontrolledTooltip
                                        placement="right"
                                        target="info-icon"
                                      >
                                        Instant payout is not enabled for this
                                        business account.
                                      </UncontrolledTooltip>
                                    </span>
                                  ) : null}
                                </Label>
                              </Col>

                              {/* Hourly Rate Field (conditionally displayed) */}
                              {isEditInstantPayoutEnabled && (
                                <Col xs="auto">
                                  <div className="input-group">
                                    <div className="input-group-text ">$</div>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      value={editInstantHourlyRate}
                                      onChange={handleEditHourlyRateChange}
                                      placeholder="Enter Hourly Rate"
                                      min="0" // Ensures no negative values
                                      // step="0.01"
                                    >
                                      ${" "}
                                    </Input>
                                  </div>
                                  {editHourlyRateError && (
                                    <div
                                      style={{
                                        color: "#e13c3c",
                                        fontSize: "0.875em",
                                      }}
                                    >
                                      {editHourlyRateError}
                                    </div>
                                  )}
                                </Col>
                              )}
                            </Row>
                            {/* </FormGroup> */}
                          </div>
                        </Col>
                      </Row>
                      <hr className="mt-0 mb-1 calendar-hr"></hr>
                      <div className="hstack gap-3">
                        <i className="ri-calendar-fill text-muted align-bottom fs-18 me-1"></i>{" "}
                        <Col md={5} lg={4}>
                          <label className="text-muted mb-0">
                            {" "}
                            {moment(shiftData.shift_date).format("ddd, MMM DD")}
                          </label>
                        </Col>
                      </div>

                      <div className="d-flex align-items-center mt-2 row">
                        <Col md={1}>
                          <i className="ri-time-fill text-primary align-bottom fs-18 me-1"></i>{" "}
                        </Col>
                        <Col md={5}>
                          <select
                            className="form-select"
                            value={shiftData.shift_start_time}
                            onChange={(e) => {
                              const fromTime = e.target.value;
                              setShiftData((prevState) => ({
                                ...prevState,
                                shift_start_time: fromTime, // Update the shift_start_time in the shiftData state
                              }));
                              setFromTime(fromTime);
                              // totalAmountCal(fromTime);
                              // Set ready to true if fromTime and endTime are valid
                              if (toTime && fromTime) {
                                setReady(true);
                              } else {
                                setReady(false); // Ensure ready is false if conditions aren't met
                              }
                            }}
                          >
                            {timeOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </Col>
                        -
                        <Col md={5}>
                          <select
                            className="form-select"
                            value={shiftData.shift_end_time}
                            onChange={(e) => {
                              const endTime = e.target.value;
                              setShiftData((prevState) => ({
                                ...prevState,
                                shift_end_time: endTime, // Update the shift_end_time in the shiftData state
                              }));
                              setToTime(endTime); // Update state
                              // totalAmountCal(endTime);
                              // Set ready to true if fromTime and endTime are valid
                              if (fromTime && endTime) {
                                setReady(true);
                              } else {
                                setReady(false); // Ensure ready is false if conditions aren't met
                              }
                            }}
                          >
                            {timeOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </Col>
                      </div>
                      {/* <div className="hstack gap-3 mt-2">
                        <i className="ri-record-circle-fill fs-18  text-primary align-bottom me-1"></i>{" "}
                        <Col md={5} lg={4}>
                        {console.log(shiftData,"shiftData")}
                          <Select
                            value={{
                              value: shiftData.roster_zone_id,
                              label: shiftData.zone_name,
                            }}
                            className={`${zoneIdError ? "is-invalid" : ""}`}
                            onChange={(selectedOption) => {
                              const { value, label } = selectedOption;
                              setShiftData((prevState) => ({
                                ...prevState,
                                roster_zone_id: value, // Update roster_zone_id
                                zone_name: label, // Update zone_name
                              }));
                              handleZone(selectedOption);
                              setZoneIdError("");
                            }}
                            options={
                              zones && Array.isArray(zones)
                                ? zones.map((item) => ({
                                    value: item.id,
                                    label: item.zone_name,
                                  }))
                                : []
                            }
                          />
                          {zoneIdError && (
                            <div className="invalid-feedback">
                              {zoneIdError}
                            </div>
                          )}
                        </Col>
                      </div> */}
                      <div className="hstack gap-3 mt-2">
                        <i className="ri-record-circle-fill fs-18 text-primary align-bottom me-1"></i>
                        <Col md={5} lg={4}>
                          {shiftData.published === "N" ? (
                            <>
                              <Select
                                value={{
                                  value: shiftData.roster_zone_id,
                                  label: shiftData.zone_name,
                                }}
                                className={`${zoneIdError ? "is-invalid" : ""}`}
                                onChange={(selectedOption) => {
                                  const { value, label } = selectedOption;
                                  setShiftData((prevState) => ({
                                    ...prevState,
                                    roster_zone_id: value, // Update roster_zone_id
                                    zone_name: label, // Update zone_name
                                  }));
                                  handleZone(selectedOption);
                                  setZoneIdError("");
                                }}
                                options={
                                  zones && Array.isArray(zones)
                                    ? zones.map((item) => ({
                                        value: item.id,
                                        label: item.zone_name,
                                      }))
                                    : []
                                }
                              />
                              {zoneIdError && (
                                <div className="invalid-feedback">
                                  {zoneIdError}
                                </div>
                              )}
                            </>
                          ) : (
                            <label className="text-muted mb-0">
                              {" "}
                              {shiftData.zone_name}
                            </label>
                          )}
                        </Col>
                      </div>

                      {breaks?.map((breakItem, index) => {
                        return (
                          <div className="hstack gap-3 mt-2" key={index}>
                            <div className="">
                              <i className="ri-cup-fill text-primary fs-18  align-bottom me-1"></i>{" "}
                            </div>
                            <div className="hstack gap-2">
                              <Col md={6}>
                                <select
                                  className="form-select"
                                  value={breakItem.break_type}
                                  onChange={(e) => {
                                    const newBreaks = [...breaks];
                                    newBreaks[index].break_type =
                                      e.target.value;
                                    setBreaks(newBreaks);
                                  }}
                                >
                                  <option value="">Select Break Type</option>
                                  <option value="Meal">Meal Break</option>
                                  <option value="Rest">Rest Break</option>
                                </select>
                              </Col>
                              <Col md={6}>
                                <select
                                  className="form-select"
                                  value={breakItem.duration}
                                  onChange={(e) => {
                                    const newBreaks = [...breaks];
                                    newBreaks[index].duration = e.target.value;
                                    setBreaks(newBreaks);
                                  }}
                                >
                                  {[...Array(48)].map((_, i) => (
                                    <option key={i} value={(i + 1) * 5}>
                                      {(i + 1) * 5} mins
                                    </option>
                                  ))}
                                </select>
                              </Col>
                            </div>

                            {index <= 0 && (
                              <button
                                onClick={handleAddBreak}
                                className="btn btn-ghost-success"
                              >
                                Add Break
                              </button>
                            )}
                            {index > 0 && (
                              <button
                                onClick={() => handleDeleteBreak(index)}
                                className="btn btn-ghost-dark"
                              >
                                <i className="ri-delete-bin-6-line text-danger align-bottom me-1"></i>{" "}
                              </button>
                            )}
                          </div>
                        );
                      })}
                      <div className="hstack gap-3 mt-2">
                        <i className="ri-chat-1-fill fs-18  text-muted align-bottom me-1"></i>{" "}
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea5"
                          rows="2"
                          value={shiftData.business_notes}
                          onChange={(e) => {
                            setShiftData((prevState) => ({
                              ...prevState,
                              business_notes: e.target.value,
                            }));
                          }}
                        ></textarea>
                      </div>
                      <hr className="mt-2 mb-2 calendar-hr"></hr>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className=" vstack  align-items-start">
                          <Label
                            htmlFor="billinginfo-lastName"
                            className="form-label mb-0 text-muted"
                          >
                            Total
                          </Label>
                          <div>
                            {totalHours && totalAmount ? (
                              <h6 className="mb-0">
                                {totalHours}Hrs - {totalAmount}
                              </h6>
                            ) : null}
                          </div>
                        </div>

                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary"
                            onClick={(e) => handleShiftUpdate(e)}
                          >
                            Update
                          </button>
                          <UncontrolledDropdown
                            isOpen={dropdownOpen1}
                            toggle={toggleDropdown1}
                          >
                            <DropdownToggle
                              className="btn btn-sm bg-soft-primary m-auto text-primary pt-2 fs-14 "
                              style={{ border: "none" }}
                            >
                              <i className="bx bx-dots-horizontal-rounded"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() =>
                                  handleDropdownItemClick(
                                    "Repeat for tomorrow edit"
                                  )
                                }
                              >
                                Repeat for tomorrow
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  handleDropdownItemClick(
                                    "Repeat for rest of the week edit"
                                  )
                                }
                              >
                                Repeat for rest of the week
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  handleDropdownItemClick(
                                    "Repeat for specific days"
                                  )
                                }
                                disabled={!isCurrentWeek}
                              >
                                Repeat for specific days
                              </DropdownItem>
                              {/* <DropdownItem
                                  onClick={() =>
                                    handleDropdownItemClick("Send Shift")
                                  }
                                >
                                  Send Shift
                                </DropdownItem> */}
                              {delete_shift && (
                                <DropdownItem
                                  onClick={() =>
                                    handleDropdownItemClick("Delete shift edit")
                                  }
                                >
                                  Delete Shift
                                </DropdownItem>
                              )}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </div>
                    </Row>
                  </ModalBody>
                </Modal>
                <Modal
                  size="md"
                  isOpen={isPublishModalOpen}
                  toggle={() => {
                    togglePublishModal();
                  }}
                  centered
                >
                  <ModalHeader className=" text-center m-auto pt-3">
                    {(publishResponse && publishResponse?.error) ||
                    publishResponse?.success ? (
                      <>
                        {publishResponse && publishResponse.success && (
                          <p className="text-start fs-13 text-success mb-2">
                            {publishResponse.success}
                          </p>
                        )}
                        {publishResponse && publishResponse.error && (
                          <p className="text-start fs-13 text-danger">
                            {publishResponse.error}
                          </p>
                        )}
                        {publishResponse && publishResponse.error && (
                          <>
                            <p className="text-start fs-13 text-dark mt-3">
                              The following workers are not available for the
                              shift:
                            </p>
                            {unPublishedWorkers?.map((worker, index) => (
                              <ol
                                key={index}
                                className="text-start fs-13 fw-normal text-info"
                              >
                                <li>{worker}</li>
                              </ol>
                            ))}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <p className="text-center fs-18">
                          Please confirm that you want to publish these shifts
                        </p>
                        <p className=" fw-semibold fs-13 my-1">
                          The team members will be notified when the shifts are
                          published
                        </p>
                        <p className=" fw-semibold fs-13 my-1">
                          Confirm to publish
                        </p>
                      </>
                    )}
                  </ModalHeader>
                  <ModalBody className="px-2 pb-3 pt-0">
                    <Row
                      className={` d-flex justify-content-center ${
                        publishResponse !== null ? "my-0" : "mt-4"
                      }`}
                    >
                      <Col
                        className={`hstack gap-3 p-0 ${
                          publishResponse !== null
                            ? "justify-content-end"
                            : "justify-content-center"
                        } `}
                      >
                        <Button
                          className={`btn btn-dark ${
                            publishResponse !== null ? "me-4 p-2 fs-12" : ""
                          }`}
                          onClick={togglePublishModal}
                        >
                          Close
                        </Button>
                        {!publishResponse && publishResponse == null && (
                          <Button
                            className={`btn btn-success `}
                            onClick={publishShifts}
                            disabled={isConfirmLoading}
                          >
                            {isConfirmLoading ? (
                              <Spinner size="sm" className="me-2">
                                {" "}
                                Loading...{" "}
                              </Spinner>
                            ) : null}
                            Confirm
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </ModalBody>
                </Modal>
                <Modal
                  size="md"
                  isOpen={toggleRepeatDays}
                  toggle={setToggleRepeatDays}
                  centered
                >
                  <ModalHeader className=" bg-primary text-white pb-3">
                    <span className="text-white">
                      {" "}
                      Repeat shift on specific days
                    </span>
                  </ModalHeader>
                  <ModalBody className="pt-1">
                    <p className="fw-semibold fs-13 my-1">
                      Select days to repeat shifts from:{" "}
                      {moment().format("ddd YYYY/MM/DD")} to{" "}
                      {moment().endOf("isoWeek").format("ddd YYYY/MM/DD")}
                    </p>

                    <div className="day-selection">
                      <div className="days mt-2 ">
                        {allDays.map((res, index) => (
                          <div
                            key={index}
                            className="form-check form-check-primary"
                          >
                            <input
                              type="checkbox"
                              className="day-check form-check-input"
                              checked={selectedDays.includes(res.value)}
                              onChange={() => handleDayClick(res.value)}
                              disabled={
                                res.value !== "Sunday" &&
                                moment()
                                  .day(res.value)
                                  .isBefore(moment().startOf("day"))
                              }
                            />
                            <Label className="form-check-label">
                              {" "}
                              {res.label}
                            </Label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <hr />
                    <div className=" form-check form-check-primary">
                      <input
                        type="checkbox"
                        id="selectAll"
                        className="day-check form-check-input"
                        checked={isAllSelected()}
                        onChange={handleSelectAll}
                      />
                      Select all
                    </div>

                    <Row className=" d-flex justify-content-center">
                      <Col className="hstack gap-3 justify-content-end">
                        <Button
                          className={`btn btn-sm btn-dark`}
                          onClick={handleRepeatDaysclick}
                        >
                          Close
                        </Button>
                        <Button
                          className={`btn btn-sm btn-primary `}
                          onClick={handleApply}
                          disabled={isButtonDisabled}
                        >
                          {isLoading ? (
                            <Spinner size="sm" className="me-2">
                              {" "}
                              Loading...{" "}
                            </Spinner>
                          ) : null}
                          Apply
                        </Button>
                      </Col>
                    </Row>
                  </ModalBody>
                </Modal>
                <Modal
                  size="md"
                  isOpen={modal_assignModal}
                  toggle={() => {
                    tog_assignModal();
                  }}
                >
                  <ModalHeader></ModalHeader>
                  <ModalBody className="text-center">
                    <h5 className="text-center">
                      The team member is not available for the next shift date.
                      Do you want to create an empty shift instead?
                    </h5>
                    <lord-icon
                      src="https://cdn.lordicon.com/xzalkbkz.json"
                      trigger="loop"
                      style={{ width: "100px", height: "100px" }}
                    ></lord-icon>
                    <Row className="mt-5 d-flex justify-content-center">
                      <Col lg={3} className=" hstack gap-3 p-0 ">
                        <Button
                          className="btn btn-dark"
                          onClick={tog_assignModal}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="btn btn-success"
                          onClick={createEmptyShiftforTomorrow}
                        >
                          Confirm
                        </Button>
                      </Col>
                    </Row>
                  </ModalBody>
                </Modal>
                <Modal
                  size="md"
                  isOpen={modal_assignModalOne}
                  toggle={() => {
                    tog_assignModalOne();
                  }}
                >
                  <ModalHeader></ModalHeader>
                  <ModalBody className="text-center">
                    <h5 className="text-center">
                      The team member is not available for the next shift date.
                      Do you want to create an empty shift instead?
                    </h5>
                    <lord-icon
                      src="https://cdn.lordicon.com/xzalkbkz.json"
                      trigger="loop"
                      style={{ width: "100px", height: "100px" }}
                    ></lord-icon>
                    <Row className="mt-5 d-flex justify-content-center">
                      <Col lg={3} className=" hstack gap-3 p-0 ">
                        <Button
                          className="btn btn-dark"
                          onClick={tog_assignModalOne}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="btn btn-success"
                          onClick={createEmptyShiftforTomorrowEdit}
                        >
                          Confirm
                        </Button>
                      </Col>
                    </Row>
                  </ModalBody>
                </Modal>
                <Modal
                  className="zoomIn"
                  id="editShift"
                  size="md"
                  isOpen={copyModal}
                  toggle={toggleCopyModal}
                  centered
                >
                  <ModalBody>
                    <h6 className="text-primary mb-2"> Dates</h6>
                    <div className="form-check mt-2 form-radio-primary mb-3">
                      <Input
                        className="form-check-input"
                        type="radio"
                        name="formradiocolor1"
                        id="radionext"
                        onChange={() => {
                          setIsAdvanced(false);
                          setCopyType("next_week");
                        }}
                      />
                      <Label className="form-check-label" for="radionext">
                        Copy to next week
                      </Label>
                    </div>

                    <div className="form-check form-radio-primary mb-3">
                      <Input
                        className="form-check-input"
                        type="radio"
                        name="formradiocolor1"
                        id="radioprev"
                        onChange={() => {
                          setIsAdvanced(false);
                          setCopyType("previous_week");
                        }}
                      />
                      <Label className="form-check-label" for="radioprev">
                        Copy from previous week
                      </Label>
                    </div>

                    <div className="form-check form-radio-primary mb-1">
                      <Input
                        className="form-check-input"
                        type="radio"
                        name="formradiocolor1"
                        id="radioadvanced"
                        onChange={() => {
                          setIsAdvanced(true);
                          setCopyType("advanced");
                        }}
                      />
                      <Label className="form-check-label" for="radioadvanced">
                        Advanced
                      </Label>
                    </div>
                    <Collapse isOpen={isAdvanced} id="collapseWithicon">
                      <div
                        className="card mt-2 mb-0"
                        style={{ backgroundColor: "#f5f5f5" }}
                      >
                        <CardBody className="pt-2">
                          <label className="">Copy shifts from</label>

                          <Select
                            options={prevWeekOptions}
                            value={selectedFromWeek}
                            styles={customSingleSelectStyles}
                            onChange={(e) => {
                              setSelectedFromWeek(e);
                              console.log(e, "getZonesForWeek e");
                              getZonesForWeek(e);
                            }}
                            formatOptionLabel={(data) => (
                              <div>{data.label}</div>
                            )}
                          />
                          <label className="mt-2">Copy shifts to</label>
                          <Select
                            options={nextWeekOptions}
                            value={selectedToWeek}
                            styles={customSingleSelectStyles}
                            onChange={setSelectedToWeek}
                            formatOptionLabel={(data) => (
                              <div>{data.label}</div>
                            )}
                          />
                          <label className="mt-2">Areas</label>
                          {console.log(areas, selectedAreas, "selected area")}
                          <Select
                            value={selectedAreas}
                            isMulti={true}
                            styles={customMultiSelectStyles}
                            onChange={handleAreaMultiSelect}
                            options={areas}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            placeholder="Select zones to copy from..."
                          />
                        </CardBody>
                      </div>
                    </Collapse>

                    <Row className=" ps-1 mt-3">
                      <Col lg={10}>
                        {isCopy && (
                          <div className="mt-2">
                            <Progress
                              color="primary"
                              striped
                              value={progress}
                            />
                            <span>Copying shifts...</span>
                          </div>
                        )}
                      </Col>

                      <Col lg={2} className="float-end">
                        <Button
                          disabled={isCopy}
                          className="btn btn-primary btn-sm  flex-end"
                          onClick={() => {
                            handleCopy();
                          }}
                        >
                          Copy
                        </Button>
                      </Col>
                    </Row>
                  </ModalBody>
                </Modal>
                <Modal
                  className="zoomIn"
                  id="editShift"
                  size="md"
                  isOpen={copyDayModal}
                  toggle={toggleCopyDayModal}
                  centered
                >
                  <ModalBody>
                    <h6 className="text-primary mb-2"> Dates</h6>
                    <div className="form-check mt-2 form-radio-primary mb-3">
                      <Input
                        className="form-check-input"
                        type="radio"
                        name="formradiocolor1"
                        id="radionext"
                        onChange={() => {
                          setIsAdvanced(false);
                          setCopyType("next_day");
                        }}
                      />
                      <Label className="form-check-label" for="radionext">
                        Copy to next day
                      </Label>
                    </div>

                    <div className="form-check form-radio-primary mb-3">
                      <Input
                        className="form-check-input"
                        type="radio"
                        name="formradiocolor1"
                        id="radioprev"
                        onChange={() => {
                          setIsAdvanced(false);
                          setCopyType("previous_day");
                        }}
                      />
                      <Label className="form-check-label" for="radioprev">
                        Copy from previous day
                      </Label>
                    </div>

                    <div className="form-check form-radio-primary mb-1">
                      <Input
                        className="form-check-input"
                        type="radio"
                        name="formradiocolor1"
                        id="radioadvanced"
                        onChange={() => {
                          setIsAdvanced(true);
                          setCopyType("advanced");
                        }}
                      />
                      <Label className="form-check-label" for="radioadvanced">
                        Advanced
                      </Label>
                    </div>
                    <Collapse isOpen={isAdvanced} id="collapseWithicon">
                      <div
                        className="card mt-2 mb-0"
                        style={{ backgroundColor: "#f5f5f5" }}
                      >
                        <CardBody className="pt-2">
                          <label className="">Copy shifts from</label>

                          <Select
                            options={prevDayOptions}
                            value={selectedFromDay}
                            styles={customSingleSelectStyles}
                            onChange={(e) => {
                              setSelectedFromDay(e);
                              console.log(e, "getZonesForDay e");
                              getZonesForDay(e);
                            }}
                            formatOptionLabel={(data) => (
                              <div>{data.label}</div>
                            )}
                          />
                          <label className="mt-2">Copy shifts to</label>
                          <Select
                            options={nextDayOptions}
                            value={selectedToDay}
                            styles={customSingleSelectStyles}
                            onChange={setSelectedToDay}
                            formatOptionLabel={(data) => (
                              <div>{data.label}</div>
                            )}
                          />
                          <label className="mt-2">Areas</label>
                          {console.log(areas, selectedAreas, "selected area")}
                          <Select
                            value={selectedAreas}
                            isMulti={true}
                            styles={customMultiSelectStyles}
                            onChange={handleAreaMultiSelect}
                            options={areas}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            placeholder="Select zones to copy from..."
                          />
                        </CardBody>
                      </div>
                    </Collapse>
                    <Row className="align-items-center ps-1 mt-3">
                      <Col lg={10}>
                        {isCopy && (
                          <div className="mt-2">
                            <Progress
                              color="primary"
                              striped
                              value={progress}
                            />
                            <span>Copying shifts...</span>
                          </div>
                        )}
                      </Col>

                      <Col lg={2} className="float-end">
                        <Button
                          disabled={isCopy}
                          className="btn btn-primary btn-sm  flex-end"
                          onClick={() => {
                            handleDayCopy();
                          }}
                        >
                          Copy
                        </Button>
                      </Col>
                    </Row>
                  </ModalBody>
                </Modal>
              </Col>

              <ToastContainer closeButton={false} limit={1} theme="light" />
            </Row>
            <div
              className="d-flex mt-0 mb-0 justify-content-between align-items-center"
              style={{ paddingLeft: "1%" }}
            >
              <button
                className="btn btn-soft-ghost d-flex ps-0 gap-2 align-items-center"
                onClick={() => handlePreviousToPage()}
              >
                <i className="ri-arrow-left-line text-primary fs-18"></i>{" "}
                <h5
                  className="mb-0  text-primary"
                  style={{ textDecoration: "underline" }}
                >
                  {" "}
                  Back to Roster
                </h5>
              </button>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}

export default wrapperFun(Calendar);
