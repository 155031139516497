module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  beampusher: {
    INSTANCE_ID: "a2403fd5-e2d9-45f4-9958-fc9962dc08e9",
    WEB_URL: "https://work.getrostered.app/",
  },
  api: {
    API_URL: " https://devbusiness.getrostered.app/server/public/api",
    WEB_URL: "https://devbusiness.getrostered.app/server/public/api",
  },
  stripe: {
    STRIPE_LIVE: "pk_live_51PQgCZKIMwyQ02NV5vtHzFGfNMEgRJOQl7PSRkZ8vpWNN937lH8Vyn7b9TMroParOfPPO3K9pbPW6LCPRTeUoKgi00GlXSA4hL",
    STRIPE_DEV: "pk_test_51PQgCZKIMwyQ02NVkd61ln75OTdn4diforIzJIfrSMbwy5IeAGBTANUSYkMRpf7WJXR7QbsrFAahxTusy77uTsEN001yPtwoyx"
  }
};
